import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthenticationService, FormUtilsService } from 'src/@hop/services';
import { fadeInUp400ms } from '../../../../../../@hop/animations/fade-in-up.animation';
import { ResponseErrorModel } from '../../../../../../@hop/models/responseErrorModel';

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormErrorsModule } from '../../../../../../@hop/components/mat-form-errors/mat-form-errors.module';
import { PasswordStrengthMeterModule } from '../../../../../../@hop/components/password-strength-meter/password-strength-meter.module';
import { UserEmailCodeComponent } from '../../../../../../@hop/components/user-email-code/user-email-code.component';

@UntilDestroy()
@Component({
  selector: 'hop-register-email',
  standalone: true,
  template: `
    <div [formGroup]="form" class="flex flex-col gap-4 flex-auto">
      <div>
        <mat-form-field class="w-full">
          <mat-label>{{ '_label.email' | translate }}</mat-label>
          <input formControlName="email" matInput (click)="clickEmail()" [readonly]="!!email" id="email" />
          <mat-error>
            <hop-mat-form-error control="email"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ 'firstName' | translate }}</mat-label>
          <input formControlName="firstName" matInput #firstNameRef id="firstName" />
          <mat-error>
            <hop-mat-form-error control="firstName"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ 'lastName' | translate }}</mat-label>
          <input formControlName="lastName" matInput id="lastName" />
          <mat-error>
            <hop-mat-form-error control="lastName"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>{{ '_account._register.email-code' | translate }}</mat-label>
          <hop-user-email-code #userEmailCodeRef [emailToSend]="email" formControlName="emailCode"></hop-user-email-code>
          <mat-error>
            <hop-mat-form-error control="emailCode"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="">
        <mat-checkbox formControlName="termsAccepted" class="caption" data-testid="hop-register-email-termsAccepted">
          <span [innerHTML]="translateService.instant('_account._register.i-accept-the-terms')"></span>
        </mat-checkbox>
        <mat-error *ngIf="form.get('termsAccepted').hasError('required') && form.get('termsAccepted').touched">{{
          '_account._register.required-to-accept' | translate
        }}</mat-error>
      </div>
      <button data-testid="button-sign-in" (click)="send()" [disabled]="loading" color="primary" mat-raised-button type="button">
        {{ 'sign-in' | translate }}
      </button>
    </div>
  `,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    TranslateModule,
    MatFormErrorsModule,
    PasswordStrengthMeterModule,
    UserEmailCodeComponent
  ],
  //styleUrls: ['./register-email.component.scss'],
  animations: [fadeInUp400ms]
})
export class RegisterEmailComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  environment = environment;
  @Input() email = '';
  @Output() emailClicked = new EventEmitter();
  @ViewChild('firstNameRef', { static: true }) firstNameRef: ElementRef;
  @ViewChild('userEmailCodeRef', { static: true }) userEmailCodeRef: UserEmailCodeComponent;
  @Input() isProfessional = false;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthenticationService,
    private formUtilsService: FormUtilsService,
    public translateService: TranslateService
  ) {}

  sentOnce = false;
  sentEmailCode = false;
  ngOnInit() {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      email: [this.email, [Validators.required, Validators.email]],
      emailCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      termsAccepted: [false, Validators.requiredTrue]
    });
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(1000)).subscribe((data) => {
      if (!this.sentEmailCode && this.form.controls['email'].valid && data.firstName) {
        this.sentEmailCode = true;
        this.userEmailCodeRef.sendEmailCode();
      }
      if (!this.sentOnce && this.form.valid && data.emailCode.length === 6 && data.termsAccepted) {
        this.send();
      }
    });
  }

  ngAfterViewInit(): void {}

  loading = false;
  send() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      //this.userEmailCodeRef.stateChanges.next();
      return;
    } else {
      this.loading = true;
      this.sentOnce = true;
      //need to accept terms first
      this.authService
        .registerEmail(this.f['email']?.value, this.f['emailCode']?.value, {
          firstName: this.f['firstName']?.value,
          lastName: this.f['lastName']?.value,
          isProfessional: this.isProfessional,
          termsAccepted: this.f['termsAccepted']?.value
        })
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            // this.store.dispatch(new UserGet()).pipe(untilDestroyed(this)).subscribe();
          },
          error: (error: { error: ResponseErrorModel }) => {
            this.loading = false;
            if (error.error.errors) {
              this.formUtilsService.setFormErrors(error.error, this.form);
            }
          },
          complete: () => {
            this.loading = false;
          }
        });
    }
  }

  get f() {
    return this.form.controls;
  }

  clickEmail() {
    this.emailClicked.emit(true);
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../../@hop/services';
import { Select, Store } from '@ngxs/store';
import { UserLogout, UserState } from '../../@hop/state';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';

@Injectable({ providedIn: 'root' })
export class IsProfessionalGuard {
  constructor(private router: Router, private authenticationService: AuthenticationService, private store: Store) {
    this.user$.subscribe((user) => {
      this.user = user;
    });
  }
  user: UserModel;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user?.isProfessional) {
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/404']);
      console.log('redirect to onboard');
      return false;
    }
  }
}

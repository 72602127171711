import { Directive, forwardRef, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
  standalone: true,
  selector: 'hop-accessor-directive',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValueAccessorDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ValueAccessorDirective)
    }
  ]
})
export class ValueAccessorDirective<T> implements ControlValueAccessor, OnDestroy {
  private onChange = (v: any) => {};
  private onTouched = (v: any) => {};
  private valueSubject$ = new Subject<T>();
  private disabledSubject$ = new Subject<boolean>();
  readonly value$ = this.valueSubject$.asObservable();
  readonly disabled$ = this.disabledSubject$.asObservable();

  constructor() {}

  ngOnDestroy(): void {
    this.valueSubject$.complete();
    this.disabledSubject$.complete();
  }

  valueChange(v: T) {
    this.onChange(v);
  }

  touchedChange(v: boolean) {
    this.onTouched(v);
  }

  writeValue(obj: any): void {
    this.valueSubject$.next(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledSubject$.next(isDisabled);
  }

  validate(control?: AbstractControl<any, any>): ValidationErrors {
    return null;
  }

  reset() {
    this.valueSubject$.next(null);
  }
}

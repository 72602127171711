import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ReplaySessionService } from '../services/open-replay-session.service';

@Injectable({ providedIn: 'root' })
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private replaySessionService: ReplaySessionService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //This function will be called with the response a few lines below
    const handleResponse = (request: HttpRequest<any>, response: HttpResponse<any>, event: string) => {
      //we forward our data to the service, which will create the custom event and send it
      this.replaySessionService.sendEventToReplaySession(event, { request, response });
    };
    return next.handle(request).pipe(
      //filter out events that aren't http reponses
      filter((event: any) => event instanceof HttpResponse),
      map((resp: HttpResponse<any>) => {
        //for each response, call handleResponse
        handleResponse(request, resp, `${request.url}`);
        return resp;
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}

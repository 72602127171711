import * as CryptoJS from 'crypto-js';
import { catchError, tap } from 'rxjs/operators';
import { from, Observable, of, Subject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';

interface CacheContent {
  expiry: number;
  value: any;
}

@Injectable()
export class CachingService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private pendingObservables: Map<string, { subject: Subject<any>; time: number }> = new Map<string, { subject: Subject<any>; time: number }>();
  readonly DEFAULT_MAX_AGE_MINUTES: number = 5;
  private userId = 0;

  constructor(/* private userService: UsersService */) {
    // userService.user.subscribe((user) => (this.userId = user.userId));
  }

  /**
   * Gets the value from cache by key
   * test pending observables
   * example:
   return this.userService.cachingService.get('tax' + this.userService.cachingService.hash(searchParams),
   this.http.get('/api/orders/tax', {params: searchParams}).pipe(map((data: any) => {
          return data.salesTax;
      }),
   bustCache?,
   expiryMinutes?
   )
   );
   * in this example the caching service will cache all different tax responses based on search params
   * this can be used in a more simple way by providing the same object all the time ex {}.
   * the source is what will be called if nothing exists in cache
   * the maxAgeMinutes defaults to 5 and it's the time to expire
   */
  remember(name: string, source: Observable<any> | Promise<any>, bustCache = false, maxAgeMinutes?: number): Observable<any> | Subject<any> {
    let key = name + this.userId;
    // console.log(key);
    if (bustCache) {
      this.delete(name);
    }
    if (this.hasValidCachedValue(key)) {
      return of(this.cache.get(key).value);
    }

    if (!maxAgeMinutes) {
      maxAgeMinutes = this.DEFAULT_MAX_AGE_MINUTES;
    }
    // clear old pending observables if any
    if (this.pendingObservables.has(key) && Date.now() - this.pendingObservables.get(key).time > 10000) {
      this.pendingObservables.delete(key);
    }
    if (this.pendingObservables.has(key)) {
      // console.log(key, 'pending observable');
      return this.pendingObservables.get(key).subject;
    } else if (source && (source instanceof Observable || source instanceof Promise)) {
      // console.log(key, 'returning source');
      if (source instanceof Promise) {
        source = from(source);
      }
      this.pendingObservables.set(key, { subject: new Subject(), time: Date.now() });
      return source.pipe(
        tap((value) => {
          // console.log(key, 'set 64');
          this.set(key, value, maxAgeMinutes);
        }),
        catchError((err) => {
          this.notifyPendingObservers(key, err, true);
          return observableThrowError(err);
        })
      );
    }
    return observableThrowError('key is not available in cache');
  }

  /**
   * Removes the value from cache by key
   * test pending observables
   * example:
   return this.userService.cachingService.delete(key);
   */
  public delete(name: string) {
    let key = name + this.userId;
    if (this.hasValidCachedValue(key)) {
      this.cache.delete(key);
    }
  }

  /**
   * Sets the value with key in the cache
   * Notifies pending observers of the new value
   */
  set(key: string, value: any, maxAgeMinutes: number = this.DEFAULT_MAX_AGE_MINUTES): void {
    this.cache.set(key, { value: value, expiry: Date.now() + maxAgeMinutes * 60 * 1000 });
    this.notifyPendingObservers(key, value);
  }

  /**
   * Checks if the a key exists in cache
   */
  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Publishes the value to all
   */
  private notifyPendingObservers(key: string, value: any, err = false): void {
    if (this.pendingObservables.has(key)) {
      const pending = this.pendingObservables.get(key).subject;
      const observersCount = pending.observers.length;
      if (observersCount) {
        if (!err) {
          pending.next(value);
        } else {
          pending.error(value);
        }
      }
      pending.complete();
      this.pendingObservables.delete(key);
      // console.log(key, 'complete and delete pending', this.pendingObservables);
    }
  }

  /**
   * Checks if the key exists and has not expired.
   */
  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      // console.log(key, this.cache.get(key).expiry - Date.now());
      if (this.cache.get(key).expiry < Date.now()) {
        // console.log(key, 'delete');
        this.cache.delete(key);
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Hash objects as sha256
   */
  public hash(...objects: any[]) {
    return this.hashCrypto(objects.reduce((acc, cur) => this.hashCrypto(acc) + this.hashCrypto(cur)));
  }

  /**
   * Hash the object as sha256
   */
  private hashCrypto(object: any) {
    return CryptoJS.SHA256(JSON.stringify(object)).toString();
  }
}

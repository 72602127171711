import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { fadeInUp400ms } from '../../../../../../@hop/animations/fade-in-up.animation';
import { Select, Store } from '@ngxs/store';
import { UserLogin } from 'src/@hop/state/actions/user.action';
import { UserState } from 'src/@hop/state/states/user-state.service';
import { Observable } from 'rxjs';
import { UserModel } from '../../../../../../@hop/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as cookie from 'js-cookie';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { UserEmailCodeComponent } from '../../../../../../@hop/components/user-email-code/user-email-code.component';
import { MatFormErrorsModule } from '../../../../../../@hop/components/mat-form-errors/mat-form-errors.module';
import { ResponseErrorModel } from '../../../../../../@hop/models/responseErrorModel';
import { AuthenticationService, FormUtilsService } from '../../../../../../@hop/services';

@UntilDestroy()
@Component({
  selector: 'hop-login-email-code',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    TranslateModule,
    RouterLink,
    UserEmailCodeComponent,
    MatFormErrorsModule
  ],
  template: `
    <div (keyup.enter)="send()" [formGroup]="form" class="flex flex-col gap-4">
      <div class="flex flex-col">
        <mat-form-field class="flex-1">
          <mat-label>{{ '_label.email' | translate }}</mat-label>
          <input formControlName="email" matInput required (click)="clickEmail()" #emailRef />
          <mat-error>
            <hop-mat-form-error control="email"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>{{ '_label.email-code' | translate }}</mat-label>
          <hop-user-email-code
            [disabled]="!f.email.valid"
            [emailToSend]="f.email.value"
            formControlName="emailCode"
            #emailCodeRef
            [checkIfEmailExists]="true"
          ></hop-user-email-code>
          <mat-error>
            <hop-mat-form-error control="emailCode"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="passwordIsEnough" class="flex items-center justify-between">
        <mat-checkbox formControlName="rememberMe" class="caption" color="primary">{{ '_account._login.remember-me' | translate }}</mat-checkbox>
      </div>

      <button *ngIf="passwordIsEnough" (click)="send()" color="primary" mat-raised-button type="button" data-testid="button-sign-in">
        {{ '_account._login.sign-in' | translate }}
      </button>
    </div>
  `,
  //styleUrls: ['./login-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp400ms]
})
export class LoginEmailCodeComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  @Input() navigateToDashboard: boolean = true;
  @Input() email = '';
  @Output() emailClicked = new EventEmitter();
  @ViewChild('emailCodeRef') public emailCodeRef: UserEmailCodeComponent;
  private loading: boolean;

  get passwordIsEnough() {
    return this.form.get('emailCode').value?.length > 5;
  }

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    private authService: AuthenticationService,
    private formUtilsService: FormUtilsService
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((e: NavigationStart) => {
      const navigation = this.router.getCurrentNavigation();
      this.email = navigation?.extras?.state ? navigation.extras.state['email'] : this.email;
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [this.email, Validators.required],
      emailCode: ['', Validators.required],
      rememberMe: [false]
    });
  }

  ngAfterViewInit(): void {}

  send() {
    if (this.form.invalid) {
      return;
    }
    this.authService
      .loginWithEmailCode(this.f['email']?.value, this.f['emailCode']?.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (this.navigateToDashboard) {
            this.router.navigate(['/dashboard']);
          }
        },
        error: (error: { error: ResponseErrorModel }) => {
          this.loading = false;
          if (error.error.errors) {
            this.formUtilsService.setFormErrors(error.error, this.form);
          }
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  get f() {
    return this.form.controls;
  }

  forgotPassword() {
    cookie.set('auth-close', 'true', {
      expires: 0.01
    });
  }

  clickEmail() {
    this.emailClicked.emit(true);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { PublicProfileModel } from '../models/public-profile.model';
import { PaginationFilterAppointmentsOptions, PaginationFilterOptions } from '../models/pagination.model';

@Injectable({ providedIn: 'root' })
export class AppointmentService {
  endpoint = `${this.environment.apiUrl}/appointments`;

  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getMyAppointmentsWithCursor(
    limit = 10,
    isClient = false,
    filter: any = {},
    sortName = 'dateStart',
    sortOrder = 'asc',
    page = 1,
    cursorUuid = '',
    showAll = false,
    previous = false
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/cursor', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('page', page)
          .set('limit', limit)
          .set('cursorUuid', cursorUuid.toString())
          .set('show-all', showAll)
          .set('previous', previous)
          .set('isClient', isClient)
        // context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getMyAppointmentsWithPagination(
    options: PaginationFilterAppointmentsOptions = {
      filter: {},
      sortName: '',
      sortOrder: 'desc',
      page: 1,
      limit: 10,
      showAll: false,
      previous: false,
      isClient: false
    }
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(options.filter))
          .set('sortName', options.sortName)
          .set('sortOrder', options.sortOrder)
          .set('page', options.page)
          .set('limit', options.limit)
          .set('show-all', options.showAll)
          .set('previous', options.previous)
          .set('isClient', options.isClient)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getAppointments(showAll: boolean = false, previous: boolean = false, limit?: number, isClient: boolean = false): Observable<any> {
    let params = new HttpParams().set('show-all', showAll).set('previous', previous);

    if (isClient) {
      params = params.set('isClient', isClient);
    }
    if (limit) {
      params = params.set('limit', limit);
    }

    return this.http.get<any>(this.endpoint, { params }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  createAppointment(appointmentInformation): Observable<any> {
    return this.http.put<any>(this.endpoint, appointmentInformation).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  editAppointment(appointmentInformation): Observable<any> {
    return this.http.patch<any>(this.endpoint, appointmentInformation).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAppointmentByUuid(uuid: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/uuid/${uuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  cancelAppointment(uuid: string, reason: { reason: string }): Observable<any> {
    return this.http.post<any>(this.endpoint + `/cancel/${uuid}`, reason).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  removeAppointmentFromCart(uuid: string) {
    return this.http.post<any>(this.endpoint + `/remove-from-order/${uuid}`, {}).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  extendReservation(uuid: string) {
    return this.http.post<any>(this.endpoint + `/extend-reservation/${uuid}`, {}).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PhoneInputComponent } from 'hop-calendar';
import { AddEmailComponent } from '../add-email/add-email.component';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form.component';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel, UserProfileModel } from 'src/@hop/models';
import { UserState } from 'src/@hop/state';
import { PublicProfileService } from 'src/@hop/services/public-profile.service';
import { combineLatest } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'dialog-contact-form',
  template: `
    <div class="">
      <div class="flex justify-between">
        <h1 *ngIf="user && userProfile" mat-dialog-title class="text-lg">
          {{ '_contact-form.contact-name' | translate: { name: userProfile?.user?.firstName + ' ' + userProfile?.user?.lastName } }}
        </h1>
        <h1 *ngIf="!user" mat-dialog-title class="text-lg">
          {{ '_contact-form.contact-professional-sign-in' | translate: { name: userProfile?.user?.firstName + ' ' + userProfile?.user?.lastName } }}
        </h1>
        <div mat-dialog-actions>
          <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
        </div>
      </div>
      <div mat-dialog-content>
        <hop-contact-form (onSendMessage)="sendMessage()" [user]="user" [professionalUser]="userProfile"></hop-contact-form>
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatDialogModule,
    PhoneInputComponent,
    AddEmailComponent,
    CommonModule,
    ContactFormComponent
  ]
})
export class DialogContactFormComponent implements OnInit {
  user: UserModel;
  userProfile: any;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  constructor(
    public dialogRef: MatDialogRef<DialogContactFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private publicUserService: PublicProfileService
  ) {}

  ngOnInit(): void {
    combineLatest([this.publicUserService.userProfile$.pipe(untilDestroyed(this)), this.user$.pipe(untilDestroyed(this))]).subscribe(
      ([userProfile, user]) => {
        this.userProfile = userProfile;
        this.user = user;
      }
    );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  addEmail() {
    this.dialogRef.close();
  }
  sendMessage() {
    this.dialogRef.close();
  }
}

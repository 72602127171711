import { Injectable } from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink, NavigationSubheading } from '../interfaces/navigation-item.interface';
import { BehaviorSubject, Subject } from 'rxjs';
import { PublicProfileModel } from '../models/public-profile.model';
import { FeaturesModel, UserModel } from '../models';
import { FeatureService } from './feature.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  items$: BehaviorSubject<NavigationItem[]> = new BehaviorSubject([]);

  private _openChangeSubject = new Subject<NavigationDropdown>();
  openChange$ = this._openChangeSubject.asObservable();

  constructor(featureService: FeatureService) { }

  triggerOpenChange(item: NavigationDropdown) {
    this._openChangeSubject.next(item);
  }

  isLink(item: NavigationItem): item is NavigationLink {
    return item.type === 'link';
  }

  isDropdown(item: NavigationItem): item is NavigationDropdown {
    return item.type === 'dropdown';
  }

  isSubheading(item: NavigationItem): item is NavigationSubheading {
    return item.type === 'subheading';
  }

  setAppMenu(user, translations, features: FeaturesModel) {
    let items: NavigationItem[] = [
      {
        type: 'link',
        label: translations['home'],
        route: '/',
        icon: 'logo:logo',
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: 'link',
        label: translations['pricing'],
        route: '/pricing',
        icon: 'mat:attach_money'
      }
      /*{
        type: 'link',
        label: translations['login'],
        route: '/sign-in',
        icon: 'mat:login'
      }*/

      /*{
        type: 'link',
        label: 'Sign Up',
        route: '/register',
        icon: 'mat:local_offer'
        // class: 'button-inverted'
      }*/
    ];
    if (user) {
      if (user.isProfessional) {
        items = [
          {
            type: 'link',
            label: translations['_menu.dashboard'],
            route: '/dashboard',
            icon: 'logo:logo'
          },

          {
            type: 'subheading',
            label: translations['_menu.content.menu-name'],
            children: [
              { type: 'link', label: translations['_menu.content.all-appointments'], route: '/appointments', icon: 'mat:date_range' },
              /*{
                type: 'link',
                label: translations['_menu.content.all-client-appointments'],
                route: '/client-appointments',
                icon: 'mat:date_range'
              },*/
              { type: 'link', label: translations['_menu.content.offer-appointments'], route: '/offer-appointments', icon: 'mat:local_offer' },
              {
                type: 'link',
                label: translations['_menu.content.services'],
                route: '/services',
                icon: 'mat:shopping_cart'
              },
              // { type: 'link', label: 'One to many', route: '/offer/create', icon: 'mat:group_add' },
              // { type: 'link', label: 'Group', route: '/offer/create', icon: 'mat:group' },
              {
                type: 'link',
                label: translations['_menu.content.availability'],
                route: '/availability',
                icon: 'mat:access_time'
              },
              {
                type: 'link',
                label: translations['_menu.content.locations'],
                route: '/account/locations',
                icon: 'mat:location_on'
              },
              /*{
                type: 'link',
                label: translations['_menu.reviews.submitted-reviews'],
                route: '/submitted-reviews',
                icon: 'mat:reviews'
              },*/
              {
                type: 'link',
                label: translations['_menu.reviews.received-reviews'],
                route: '/received-reviews',
                icon: 'mat:star_rate'
              },
              {
                type: 'link',
                label: translations['_stories.stories'],
                route: '/stories',
                icon: 'mat:article'
              },
              {
                type: 'link',
                label: translations['_contacts.contacts'],
                route: '/contacts',
                icon: 'mat:contacts'
              },
              {
                type: 'link',
                label: translations['_pages.pages'],
                route: '/pages',
                icon: 'mat:web'
              },
              {
                type: 'link',
                label: translations['_menu.content.orders'],
                route: '/orders',
                icon: 'mat:shopping_cart'
                //disabled: !features.isOrders.value
              }
              /*{
                type: 'link',
                label: translations['_menu.content.client-orders'],
                route: '/client-orders',
                icon: 'mat:shopping_cart'
                //disabled: !features.isOrders.value
              }*/
            ]
          },
          {
            type: 'subheading',
            label: translations['_menu.new.menu-name'],
            children: [
              {
                type: 'link',
                label: translations['_menu.new.new-offerAppointment'],
                route: '/offer/create',
                icon: 'mat:local_offer'
              },
              {
                type: 'link',
                label: translations['_menu.new.new-service'],
                route: '/service/create',
                icon: 'mat:shopping_cart'
              },
              {
                type: 'link',
                label: translations['_menu.new.new-availability'],
                route: '/availability',
                icon: 'mat:access_time'
              },
              {
                type: 'link',
                label: translations['_menu.new.new-location'],
                route: '/account/locations',
                queryParams: { create: true },
                icon: 'mat:location_on'
              },
              {
                type: 'link',
                label: translations['_menu.new.new-story'],
                route: '/story/create',
                icon: 'mat:article'
              },
              {
                type: 'link',
                label: translations['_menu.new.new-page'],
                route: '/page/create',
                icon: 'mat:web'
              },
              {
                type: 'link',
                label: translations['_menu.new.new-contact'],
                route: '/contacts',
                queryParams: { create: true },
                icon: 'mat:person_add'
              }
            ]
          }

          // {
          //   type: 'link',
          //   label: translations['help'],
          //   route: '/help',
          //   badge: {
          //     value: '12',
          //     bgClass: 'bg-deep-purple',
          //     textClass: 'text-deep-purple-contrast'
          //   },
          //   icon: 'mat:local_offer'
          // }
        ];
      } else {
        items = [
          {
            type: 'link',
            label: translations['_menu.dashboard'],
            route: '/dashboard',
            icon: 'logo:logo'
          },

          {
            type: 'subheading',
            label: translations['_menu.content.menu-name'],
            children: [
              { type: 'link', label: translations['_menu.content.all-appointments'], route: '/client-appointments', icon: 'mat:date_range' },
              {
                type: 'link',
                label: translations['_menu.content.client-orders'],
                route: '/client-orders',
                icon: 'mat:shopping_cart'
                //disabled: !features.isOrders.value
              },
              {
                type: 'link',
                label: translations['_menu.reviews.submitted-reviews'],
                route: '/submitted-reviews',
                icon: 'mat:reviews'
              }
            ]
          }

          // {
          //   type: 'link',
          //   label: translations['help'],
          //   route: '/help',
          //   badge: {
          //     value: '12',
          //     bgClass: 'bg-deep-purple',
          //     textClass: 'text-deep-purple-contrast'
          //   },
          //   icon: 'mat:local_offer'
          // }
        ];
      }
    }
    //console.log('items', items);
    this.items$.next(items);
  }

  setUserPublicProfileMenu(user: UserModel, translations: any[], userPublicProfile: PublicProfileModel) {
    let items: NavigationItem[] = [
      /*{
        type: 'link',
        label: translations['_general.profile'],
        route: '/@' + userPublicProfile.user.username,
        icon: 'mat:person',
        routerLinkActiveOptions: { exact: true }
      },*/
      {
        type: 'link',
        label: translations['_menu.content.services'],
        route: '/@' + userPublicProfile?.user.username + '/s',
        icon: 'mat:shopping_cart'
      },
      {
        type: 'link',
        label: translations['_stories.stories'],
        route: '/@' + userPublicProfile?.user.username + '/stories',
        icon: 'mat:article'
      },
      {
        type: 'link',
        label: translations['_menu.content.all-appointments'],
        route: '/client-appointments',
        icon: 'mat:date_range'
      }
      /*{
        type: 'link',
        label: translations['login'],
        route: '/sign-in',
        icon: 'mat:settings'
      }*/
    ];
    if (userPublicProfile?.user) {
      items = [
        /*{
          type: 'link',
          label: translations['_general.profile'],
          route: '/@' + userPublicProfile.user.username,
          icon: 'mat:person',
          routerLinkActiveOptions: { exact: true }
        },*/
        {
          type: 'link',
          label: translations['_menu.content.services'],
          route: '/@' + userPublicProfile?.user.username + '/s',
          disabled: userPublicProfile._productsCount === 0,
          icon: 'mat:shopping_cart'
        },
        {
          type: 'link',
          label: translations['_stories.stories'],
          route: '/@' + userPublicProfile?.user.username + '/stories',
          disabled: userPublicProfile._storiesCount === 0,
          icon: 'mat:article'
        },
        {
          type: 'link',
          label: translations['_general.contact'],
          route: '/@' + userPublicProfile?.user.username + '/contact',
          disabled: userPublicProfile._hasContactPage === false,
          icon: 'mat:article'
        }
        /*{
          type: 'link',
          label: translations['_menu.content.my-appointments'],
          route: '/client-appointments',
          icon: 'mat:date_range'
        }*/
      ];
    } else {
      items = [];
    }
    this.items$.next(items);
  }
}

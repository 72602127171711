import { Inject, Injectable } from '@angular/core';
import { FeaturesModel } from '../models';
import { Select, Store } from '@ngxs/store';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class FeatureService {
  features: FeaturesModel;
  constructor(private store: Store, @Inject('environment') private environment) {
    this.features = environment.features;
  }

  setFeatureStateFromParams(params: Params) {
    Object.keys(params)
      .filter((key) => key.match(/^feature-(.+)$/))
      .forEach((key) => {
        this.setValue(key.replace('feature-', ''), params[key] === 'true');
      });
    return { ...this.features };
  }

  setValue(name, value) {
    this.features[name] = value;
    return { ...this.features };
  }

  get() {
    return { ...this.features };
  }

  getFeature(name: string): boolean {
    return this.features[name]?.value || false;
  }
}

import * as CryptoJS from 'crypto-js';
import { hashSync } from 'bcryptjs';

export class Utils {}

export function uuid() {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function extractFirstNWords(htmlContent: string, numberOfWords: number, url?: string): string {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlContent, 'text/html');

  function extractTextWithSpaces(node: Node): string {
    let text = '';
    node.childNodes.forEach((child) => {
      if (child.nodeType === child.TEXT_NODE) {
        text += child.textContent;
      } else if (child.nodeType === child.ELEMENT_NODE) {
        const element = child as Element;
        const tagName = element.tagName.toLowerCase();
        if (!['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName)) {
          text += ' ' + extractTextWithSpaces(child);
        }
      }
    });
    return text;
  }

  const textContentWithSpaces = extractTextWithSpaces(dom.body).trim();
  const words = textContentWithSpaces.split(/\s+/);
  let truncatedText = words.slice(0, numberOfWords).join(' ');

  if (words.length > numberOfWords) {
    truncatedText += '...';
  }

  // If a URL is provided, append the link symbol at the end of the text
  if (url) {
    truncatedText += ` <a href="${url}" target="_blank">↗</a>`;
  }

  return truncatedText;
}

declare global {
  interface Object {
    _has(path: string): boolean;

    _get(path: string, fallback: any): any;

    _slug(): string;
    _tag(): string;
  }
}

Object.defineProperty(Object.prototype, '_has', {
  value: function (needle) {
    return !(this._get(needle) == null || typeof this._get(needle) === 'undefined');
  }
});

Object.defineProperty(Object.prototype, 'string', {
  value: function (needle) {
    try {
      const h = hashSync(JSON.stringify(this), 5);
      const e = CryptoJS.AES.encrypt(h, '-' + needle).toString();
      return btoa(e).toString();
    } catch (e) {
      return '';
    }
  }
});

Object.defineProperty(Object.prototype, '_get', {
  value: _get
});

export function _get(needle, fallback) {
  let obj = this;
  let needles = needle.split('.');
  let needles_full = [];
  let needles_square;
  for (let i = 0; i < needles.length; i++) {
    needles_square = needles[i].split('[');
    if (needles_square.length > 1) {
      for (let j = 0; j < needles_square.length; j++) {
        if (needles_square[j].length) {
          needles_full.push(needles_square[j]);
        }
      }
    } else {
      needles_full.push(needles[i]);
    }
  }
  for (let i = 0; i < needles_full.length; i++) {
    let res = needles_full[i].match(/^((\d+)|"(.+)"|'(.+)')\]$/);
    if (res !== null) {
      for (let j = 0; j < res.length; j++) {
        if (res[j] !== undefined) {
          needles_full[i] = res[j];
        }
      }
    }

    if (obj[needles_full[i]] == null || typeof obj[needles_full[i]] === 'undefined') {
      return typeof fallback !== 'undefined' ? fallback : undefined;
    }
    obj = obj[needles_full[i]];
  }
  return obj;
}

Object.defineProperty(String.prototype, '_slug', {
  value: function () {
    let obj = this;
    if (!obj) {
      return '';
    }
    return obj
      .trim() // remove spaces from both ends
      .toLowerCase() // convert everything to lower case
      .replace(/\s+/g, '-') // replace spaces (several spaces in a row will be replaced by only one dash)
      .replace(/&/g, '-and-') // replace '&' with '-and-'
      .replace(/[^\w\-]+/g, '') // remove all non-word characters
      .replace(/\-\-+/g, '-'); // replace multiple dashes into one
  }
});

Object.defineProperty(String.prototype, '_tag', {
  value: function () {
    let obj = this;
    if (!obj) {
      return '';
    }
    return obj
      .trim() // remove spaces from both ends
      .toLowerCase() // convert everything to lower case
      .replace(/\s+/g, '-') // replace spaces (several spaces in a row will be replaced by only one dash)
      .replace(/&/g, '-and-') // replace '&' with '-and-'
      .replace(/[^\w\-]+/g, '') // remove all non-word characters
      .replace(/\-\-+/g, '-'); // replace multiple dashes into one
  }
});

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuUserComponent } from './menu-user.component';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MenuUserComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatProgressBarModule,
    RouterModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    TranslateModule
  ],
  exports: [MenuUserComponent]
})
export class MenuUserModule {}

import { AuthenticationService } from '../../@hop/services';
import { AppConfigService } from '../../@hop/services/app-config.service';

export function appInitializer(authenticationService: AuthenticationService, appConfigService: AppConfigService) {
  return () =>
    new Promise((resolve) => {
      // wait 10 seconds to simulate some kind of async process
      setTimeout(() => {
        resolve(true);
      }, 3000);
      // attempt to refresh token on app start up to auto authenticate
      appConfigService.setConfig().subscribe(() => {
        resolve(true);
      });
      // @todo check if this is still needed
      //authenticationService.refreshToken().subscribe();
    });
}

import { Inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { FeaturesGet, FeatureSet, FeatureSetFromParams, FeatureRemove } from '../actions/features.action';
import { FeatureService } from '../../services/feature.service';
import { FeaturesModel } from '../../models';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { FilterAdd, FiltersGet } from '../actions/filters.action';
import {
  PaginationFilterAppointmentsOptions,
  PaginationFilterOptions,
  PaginationFilterPageOptions,
  PaginationFilterReviewsOptions
} from 'src/@hop/models/pagination.model';

export class FiltersStateModel {
  filters: any[];
}

@State<FiltersStateModel>({
  name: 'filtersState',
  defaults: {
    filters: []
  }
})
@Injectable()
export class FiltersState implements NgxsOnInit {
  @Select(FiltersState.selectFilters) filters$: Observable<FilterAndSortModel>;

  constructor(private featureService: FeatureService, @Inject('environment') private environment, private store: Store) {
    // this.filters$$.subscribe((features) => {
    //   this.featureService.features = features;
    // });
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    // ctx.dispatch(new FilterAdd('', true))
    // this.addMissingKeys(ctx, this.environment);
    // this.removeMissingKeys(ctx, this.environment);
  }

  @Selector()
  static selectFilters(state: FiltersStateModel) {
    return state ? state.filters : [];
  }

  @Selector()
  static selectPageFilter(state: FiltersStateModel) {
    return state ? state.filters.find((f) => f.path === FilterPath.pages) : {};
  }
  @Selector()
  static selectStoriesFilter(state: FiltersStateModel) {
    return state ? state.filters.find((f) => f.path === FilterPath.stories) : {};
  }
  @Selector()
  static selectOffersFilter(state: FiltersStateModel) {
    return state ? state.filters.find((f) => f.path === FilterPath['offer-appointments']) : {};
  }
  @Selector()
  static selectProductsFilter(state: FiltersStateModel) {
    return state ? state.filters.find((f) => f.path === FilterPath.products) : {};
  }
  @Selector()
  static selectSubmittedReviewsFilter(state: FiltersStateModel) {
    return state ? state.filters.find((f) => f.path === FilterPath['submitted-reviews']) : {};
  }

  @Selector()
  static selectReceivedReviewsFilter(state: FiltersStateModel) {
    return state ? state.filters.find((f) => f.path === FilterPath['received-reviews']) : {};
  }

  @Action(FiltersGet)
  filtersGet(ctx: StateContext<FiltersStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state
      // filters: this.featureService.get() //here the data coming from the API will get assigned to the users variable inside the appstate
    });
  }

  @Action(FilterAdd)
  filterAdd(ctx: StateContext<FiltersStateModel>, filter: FilterAdd) {
    const state = ctx.getState();
    const filters = state.filters;
    if (filters.find((f) => f.path === filter.path)) {
      const filterToEditIndex = filters.findIndex((f) => f.path === filter.path);
      filters[filterToEditIndex] = filter;
    } else {
      filters.push(filter);
    }
    // filters.push()
    ctx.patchState({
      ...state,
      filters
    });
    return state;
  }

  // @Action(FeatureRemove)
  // featureRemove(ctx: StateContext<FiltersStateModel>, { name }: FeatureRemove) {
  //   const features = this.featureService.get();
  //   delete features[name];
  //   const state = ctx.getState();
  //   ctx.patchState({
  //     ...state,
  //     features
  //   });
  //   return state;
  // }
}

interface FilterAndSortModel {
  path: FilterPath;
  data: Partial<PaginationFilterOptions | PaginationFilterPageOptions | PaginationFilterAppointmentsOptions | PaginationFilterReviewsOptions>;
}

enum FilterPath {
  pages = 'pages',
  products = 'products',
  'offer-appointments' = 'offer-appointments',
  stories = 'stories',
  appointments = 'appointments',
  'submitted-reviews' = 'submitted-reviews',
  'received-reviews' = 'received-reviews'
}

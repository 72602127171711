import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { OrderProduct } from 'src/@hop/models/order-product.model';
import { LayoutService } from 'src/@hop/services';
import { AppointmentService } from 'src/@hop/services/appointment.service';
import { TimeUtilsService } from 'src/@hop/services/timeUtils.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LabelsOfferProductComponent } from '../appointment/labels-offer-product.component';
import { ReservedTimerComponent } from '../appointment/reserved-timer.component';

@Component({
  selector: 'hop-order-appointments-dates',
  standalone: true,
  imports: [CommonModule, MatIconModule, RouterModule, TranslateModule, LabelsOfferProductComponent, ReservedTimerComponent],
  template: `
    <div *ngIf="orderProduct && orderProduct.productCode" class="flex flex-col space-y-1">
      <p *ngIf="showText && (orderProduct?.appointments.length || !isCancelled)" class="self-start">{{ '_basket.appointments' | translate }}:</p>
      <div class="flex flex-col space-y-1">
        <div *ngFor="let quantity of getQuantityArray(); let i = index" class="flex flex-col w-full space-y-2">
          <div class="flex flex-row w-full pl-2 text-base">
            <div class="flex flex-row justify-between pl-1 w-full">
              <div class="flex flex-row">
                <a
                  data-testid="hop-order-appointments-dates-appointment"
                  *ngIf="orderProduct?.appointments[i]?.dateStart"
                  [routerLink]="orderProduct?.appointments[i]?._uri"
                  class=" pr-3 underline"
                >
                  {{ i + 1 }}.
                  {{
                    timeUtilsService.transformAppointmentDateInFormattedDate(orderProduct?.appointments[i]?.dateStart, {
                      showTimezone: false,
                      timezone: orderProduct?.appointments[i]?.timezone?.name
                    })
                  }}</a
                >
                <a
                  data-testid="hop-order-appointments-dates-add"
                  (click)="addAppointmentDate(orderProduct)"
                  *ngIf="!isCancelled && !orderProduct?.appointments[i]?.dateStart"
                  class=" cursor-pointer"
                  target="_blank"
                >
                  {{ i + 1 }}. <span class="underline">{{ '_basket.add-appointment-date' | translate }}</span></a
                >
              </div>
              <div class="flex flex-row">
                <div *ngIf="orderProduct?.appointments[i]?.dateStart" class="flex">
                  <mat-icon
                    data-testid="card-order-appointment-dates-cancel"
                    (click)="cancelAppointment(orderProduct?.appointments[i]?.uuid)"
                    *ngIf="!readonly"
                    class="cursor-pointer"
                    >delete
                  </mat-icon>
                </div>
                <hop-reserved-timer
                  [dateReservedUntilString]="orderProduct?.appointments[i]?._dateAddedToOrderReservedUntil"
                  [totalTimeMinutes]="orderProduct?.appointments[i]?._dateAddedToOrderReservedMinutes"
                  [appointmentUuid]="orderProduct?.appointments[i]?.uuid"
                ></hop-reserved-timer>
              </div>
            </div>
          </div>
          <div *ngIf="orderProduct?.appointments[i]?.dateStart" class="flex flex-row items-center space-x-1 pl-4">
            <span>{{ orderProduct?.appointments[i].offerAppointment?.name }}</span>
            <hop-labels-offer-product [offer]="orderProduct?.appointments[i].offerAppointment"></hop-labels-offer-product>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OrderAppointmentsDatesComponent {
  @Input() orderProduct: OrderProduct;
  @Input() showText: boolean = true;
  @Input() readonly: boolean = false;
  @Output() appointmentDeleted = new EventEmitter();
  @Input() isCancelled!: boolean;
  constructor(
    public timeUtilsService: TimeUtilsService,
    private router: Router,
    private layoutService: LayoutService,
    private appointmentService: AppointmentService
  ) {}

  getButtonTimes(): number[] {
    const diff = this.orderProduct?.quantity - this.orderProduct?.appointments.length;
    return Array(diff > 0 ? diff : 0).fill(0);
  }

  getQuantityArray(): number[] {
    return Array(this.orderProduct?.quantity).fill(0);
  }

  addAppointmentDate(orderProduct: OrderProduct) {
    let navigateUrl = orderProduct?._product?._uri;
    let queryParams = { scrollToOffers: true, preferredOrderCode: orderProduct.orderCode };

    /*if (orderProduct._product?._uri) {
      navigateUrl = orderProduct._offer._uri;
      queryParams = { customUrlProduct: orderProduct._product.customUrl };
    }*/

    this.router.navigate([navigateUrl], { queryParams: queryParams });
    this.layoutService.closeBasket();
  }

  cancelAppointment(appointmentUuid: string) {
    this.appointmentService.removeAppointmentFromCart(appointmentUuid).subscribe(() => {
      this.appointmentDeleted.emit(true);
    });
  }
}

<div class="">
  <div class="flex justify-between">
    <h1 *ngIf="data?.setPassword" mat-dialog-title class="text-lg">{{ '_menu-user.set-password' | translate }}</h1>
    <h1 *ngIf="data?.verifyEmail" mat-dialog-title class="text-lg">{{'_menu-user.verify-email' | translate }}</h1>
    <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div>
  <div class="my-4" mat-dialog-content>
    <hop-set-password *ngIf="data?.setPassword"></hop-set-password>
    <hop-verify-email *ngIf="data?.verifyEmail"></hop-verify-email>
  </div>
</div>
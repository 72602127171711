<div [formGroup]="form" class="flex flex-col">
  <mat-form-field *ngIf="user?.email" class="w-full" appearance="outline">
    <mat-label>{{'email'|translate}}</mat-label>
    <input disableControl="true" readonly type="email" matInput formControlName="email" />
  </mat-form-field>
  <div class="mb-4" *ngIf="!user?.email">
    <button (click)="addEmail()" mat-button color="primary">{{'_edit_phone_email.add-email-address'|translate}}</button>
  </div>

  <div data-testid="element-phone" *ngIf="user?.phone" class="flex space-x-1">

    <mat-form-field class="w-full" appearance="outline">
      <mat-label>{{'phone'|translate}}</mat-label>
      <input disableControl="true" readonly type="phone" matInput formControlName="phone" />
    </mat-form-field>
    <button data-testid="button-phone-delete" *ngIf="user?.email" class="self-start" mat-icon-button
      (click)="deletePhoneNumber()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div class="mb-4" *ngIf="!user?.phone">
    <button data-testid="button-phone-add" (click)="addPhoneNumber()" mat-button
      color="primary">{{'_edit_phone_email.add-phone-number'|translate}}</button>
  </div>


  <div class="flex-col md:flex-row md:space-y-0">
    <mat-form-field class="w-full md:w-1/2 md:pr-2" floatLabel="auto">
      <mat-label>{{ 'lastName' | translate }}</mat-label>
      <input matInput formControlName="lastName" />
      <mat-error><hop-mat-form-error control="lastName"></hop-mat-form-error></mat-error>
    </mat-form-field>
    <mat-form-field class="w-full md:w-1/2 md:pl-2" floatLabel="auto">
      <mat-label>{{ 'firstName' | translate }}</mat-label>
      <input matInput formControlName="firstName" />
      <mat-error><hop-mat-form-error control="firstName"></hop-mat-form-error></mat-error>
    </mat-form-field>
  </div>
  <mat-form-field class="w-full" floatLabel="auto">
    <mat-label>{{ 'username' | translate }}</mat-label>
    <input matInput formControlName="username" />
    <mat-error><hop-mat-form-error control="username"></hop-mat-form-error></mat-error>
  </mat-form-field>
  <div *ngIf="user?.isGuest">
    <mat-checkbox class='caption' color='primary' data-testid='checkbox-accept-terms' formControlName='_termsAccepted'>
      <span [innerHTML]=" translateService.instant('_account._register.i-accept-the-terms')"></span>
    </mat-checkbox>
    <mat-error *ngIf="form.get('_termsAccepted')?.hasError('required') && form.get('_termsAccepted')?.touched">{{
      '_account._register.required-to-accept' | translate
      }}</mat-error>
  </div>
  <a data-testid="button-profile-update" (click)="submit()" class="w-full md:w-40 self-end" color="primary"
    mat-raised-button [disabled]="!form.dirty || !form.valid || submitted">{{"_general.update" | translate}}</a>
</div>
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../../@hop/services';
import * as cookie from 'js-cookie';
import { Select } from '@ngxs/store';
import { AppState } from '../state/states/app-state.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { finalize } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  apiName = null;
  lang = 'en';
  @Select(AppState.selectLanguage) language$: Observable<any>;
  constructor(private authenticationService: AuthenticationService, private loader: LoadingBarService) {
    this.language$.subscribe((data) => {
      if (data?.name) this.lang = data.code;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (/\/api/g.test(request.url)) {
      this.apiName = cookie.get('apiName') || '';
      if (this.apiName) {
        request = request.clone({
          setHeaders: { 'X-Server-Select': `${this.apiName}` }
        });
      }
      if (this.lang) {
        request = request.clone({
          setHeaders: { 'x-lang': `${this.lang}` }
        });
      }
      request = request.clone({
        setHeaders: { 'X-Ray-Server': `${environment.apiUrl}` }
      });

      if (request.body) {
        const ray: any = environment.apiUrl;
        request = request.clone({
          setHeaders: { 'X-Ray': request.body.string(ray) }
        });
      }
    }
    const state = this.loader.useRef(request.url);
    state.start();
    return next.handle(request).pipe(
      finalize(() => {
        state.complete();
      })
    );
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { CachingService } from './caching.service';

@Injectable({ providedIn: 'root' })
export class AppPagesService {
  endpoint = `${this.environment.apiUrl}/pages/app`;

  constructor(private http: HttpClient, @Inject('environment') private environment, private cachingService: CachingService) {}

  getAppPage(pageSlug): Observable<any> {
    return this.cachingService.remember(
      pageSlug,
      this.http.get<any>(this.endpoint + '/slug/' + pageSlug).pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      ),
      false,
      1
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LayoutService } from 'src/@hop/services/layout.service';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@UntilDestroy()
@Component({
  selector: 'app-open-menu-user',
  templateUrl: './open-menu-user.component.html',
  styleUrls: ['./open-menu-user.component.scss']
})
export class OpenMenuUserComponent implements OnInit {
  activate: boolean = false;
  constructor(private layoutService: LayoutService, private router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
      this.layoutService.closeMenuUser();
    });
  }

  @Input() isMenuActive = false;

  toggleMenu() {
    this.isMenuActive = !this.isMenuActive;

    if (this.isMenuActive) {
      this.layoutService.openMenuUser();
    } else {
      this.layoutService.closeMenuUser();
    }
  }
  ngOnInit(): void {
    this.layoutService.menuOpen$.pipe(untilDestroyed(this)).subscribe((open) => {
      if (!open && this.isMenuActive) {
        this.isMenuActive = false;
      }
    });
  }

  // setFeatureValue(name, value) {
  //   this.store.dispatch(new FeatureSet(name, value));
  // }

  openMenuUser(): void {
    this.layoutService.openMenuUser();
  }
}

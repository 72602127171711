import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PublicProfileService } from '../../services/public-profile.service';
import { LayoutService } from '../../services';

@Component({
  selector: 'hop-checkout-button',
  template: `
    <button class="py-5" mat-raised-button color="primary" (click)="openCheckout()">
      {{ '_basket.checkout-now' | translate }}
    </button>
  `,
  standalone: true,
  imports: [NgIf, MatButtonModule, TranslateModule, RouterLink]
})
export class CheckoutButtonComponent implements OnInit {
  checkoutUri: string = '';
  constructor(public publicProfileService: PublicProfileService, private layoutService: LayoutService, private router: Router) {
    this.publicProfileService.username$.subscribe((username) => {
      this.checkoutUri = '/@' + username + '/checkout';
    });
  }

  ngOnInit(): void {
    //this.getPaymentProviders();
  }

  openCheckout() {
    this.layoutService.closeBasket();
    this.router.navigate([this.checkoutUri]);
  }
}

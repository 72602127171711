<div class="body-2 p-6 bg-primary text-primary-contrast">
  <p>Debug Tool</p>
  <h5>{{appVersion$|async}}</h5>
  <!-- <div class="display-1">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div> -->
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>Features</h3>
  <!-- <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Business Appointment</h4>
    <p matLine>In 16 Minutes, Appointment Room</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Ask for Vacation</h4>
    <p matLine>12:00 PM</p>
  </a> -->
  <div class="flex flex-col" *ngFor="let feature of features$ | async | keyvalue">
    <mat-checkbox [disabled]="!(feature?.value?.value===true ||feature?.value?.value===false)"
      [checked]="feature?.value?.value" (click)="setFeatureValue(feature?.key, feature?.value)">{{
      feature.key
      }} ({{feature?.value?.value}})</mat-checkbox>
  </div>


  <h3 matSubheader>Others</h3>

  <!-- <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Dinner with Sophie</h4>
    <p matLine>18:30 PM</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Deadline for Project X</h4>
    <p matLine>21:00 PM</p>
  </a>
  <mat-divider></mat-divider>
  <h3 matSubheader>TODO-LIST</h3>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>OfferAppointment Jack to play golf</h4>
    <p matLine>Added: 6 hours ago</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Get to know Angular more</h4>
    <p matLine>Added: 2 days ago</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matLine>Configure that new router</h4>
    <p matLine>Added: 5 days ago</p>
  </a>
  <mat-divider></mat-divider>
  <h3 matSubheader>SERVER STATISTICS</h3>
</mat-nav-list>

<div class="hop-list-item" matRipple>
  <p>CPU Load (71% / 100%)</p>
  <p class="progress-bar">
    <mat-progress-bar color="primary" mode="determinate" value="71"></mat-progress-bar>
  </p>
</div>

<div class="hop-list-item" matRipple>
  <p>RAM Usage (6,175 MB / 16,384 MB)</p>
  <p class="progress-bar">
    <mat-progress-bar color="accent" mode="determinate" value="34"></mat-progress-bar>
  </p>
</div>

<div class="hop-list-item" matRipple>
  <p>CPU Temp (43° / 80°)</p>
  <p class="progress-bar">
    <mat-progress-bar color="warn" mode="determinate" value="54"></mat-progress-bar>
  </p>
</div> -->
</mat-nav-list>
<section class='flex p-1'>
  <mat-form-field appearance="fill">
    <mat-label>Api ({{apiName$|async}})</mat-label>
    <mat-select name="Api Name" (valueChange)='selectedApiName($event)'>
      <mat-option *ngFor="let apiName of apiNames" [value]='apiName'>
        {{apiName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</section>
<section class='flex p-1'>
  <button mat-raised-button color='accent' class='mr-3' (click)='refreshPage()'>Refresh page</button>
  <button mat-raised-button color='accent' (click)='clearStorage()'>Clear Storage</button>
  <button mat-raised-button color='accent' (click)='simulateError()'>Simulate Error</button>
  <button mat-raised-button color='accent' (click)='removeTermsAccepted()'>Remove
    Terms Accepted</button>
  <button mat-raised-button color='accent' (click)='removeTestUser()'>Remove User</button>
</section>
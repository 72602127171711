import { Component, Inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FeatureSet } from 'src/@hop/state/actions/features.action';
import { FeaturesModel } from 'src/@hop/models/featuresModel';
import { FeaturesState } from 'src/@hop/state/states/features-state.service';
import { AppState } from '../../../app/state/states/app-state.service';
import { ApiNameSet } from '../../state';
import { Meta } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { UserService } from '../../services';

@Component({
  selector: 'hop-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  @Select(AppState.selectApiName) apiName$: Observable<string>;

  apiNames: string[] = ['default', 'www', 'www-dev', 'www-dev1', 'www-dev2', 'www-dev3', 'www-adu', 'www-iusti'];
  apiNameSelected = 'default';
  @Select(AppState.selectAppVersion) appVersion$: Observable<string>;

  constructor(
    private store: Store,
    @Inject('environment') public environment,
    private meta: Meta,
    private userService: UserService
  ) {}

  ngOnInit() {}

  setFeatureValue(name, value) {
    value.value = !value?.value;
    this.store.dispatch(new FeatureSet(name, value));
    // console.log(name, value);
  }

  selectedApiName(apiName: string) {
    this.store.dispatch(new ApiNameSet(apiName));
  }

  refreshPage() {
    // @ts-ignore
    window.location = window.location.href + '?eraseCache=true&' + Math.random();
  }
  clearStorage() {
    localStorage.clear();
    this.refreshPage();
  }

  simulateError() {
    Sentry.captureException(new Error('This is my fake error message'));
    this.getRectArea(3, 'A');
  }

  getRectArea(width, height) {
    if (isNaN(width) || isNaN(height)) {
      throw new Error('Test error!');
    }
  }

  removeTestUser() {
    // call /api/users/test/remove-test-user
    this.userService.removeTestUser().subscribe((data) => {});
    this.refreshPage();
  }
}

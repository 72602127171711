import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationModel, LocationType } from '../../models/location.model';
import { ProductModel, ProductType } from '../../models/product.model';
import { OfferModel, OfferType } from '../../models/offer.model';
import { OfferAppointmentModel } from '../../models/appointment.model';
import { LocationIconComponent } from '../location-icon/location-icon.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [NgIf, TranslateModule, NgForOf, LocationIconComponent, JsonPipe],
  selector: 'hop-labels-offer-product',
  template: `
    <div class="inline space-y-1">
      <div
        *ngIf="product?.type"
        class="mr-1 inline-block select-none whitespace-nowrap rounded bg-base py-1.5 px-3 font-sans text-xs font-bold w-fit"
      >
        <span class="">{{ '_service._type.' + product.type | translate }}</span>
      </div>
      <div
        *ngIf="!product && offer?.type"
        class="mr-1 inline-block select-none whitespace-nowrap rounded bg-base py-1.5 px-3 font-sans text-xs font-bold w-fit"
      >
        <span class="">{{ '_offerAppointment._type.' + offer.type | translate }}</span>
      </div>
      <!--<div
        *ngFor="let virtualInPersonType of virtualInPersonTypes"
        class="inline-block select-none whitespace-nowrap rounded bg-base py-1.5 px-3 font-sans text-xs font-bold w-fit"
      >
        <span class="">{{ '_location-type-label.' + virtualInPersonType | translate }}</span>
      </div>-->
      <div
        *ngFor="let location of locations"
        class="mr-1 inline-block select-none whitespace-nowrap rounded bg-base py-1.5 px-3 font-sans text-xs font-bold w-fit"
      >
        <!--<hop-location-icon class="scale-50 origin-top-left" [location]="location"></hop-location-icon>-->
        <span class="">{{ '_location-type-label.' + location.type | translate }}</span>
      </div>
    </div>
  `
})
export class LabelsOfferProductComponent implements OnChanges {
  locations: LocationModel[];
  virtualInPersonTypes: 'online' | 'in-person'[];
  @Input() product: Partial<ProductModel>;
  offerType: OfferType;
  @Input() offer: Partial<OfferAppointmentModel>;

  constructor(public translateService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.product) {
      const offers = this.product.manyOfferAppointments;
      if (offers) {
        this.locations = offers.reduce((prev, curr) => {
          if (!prev.find((l) => l.type === curr.location.type)) {
            prev.push(curr.location);
          }
          return prev;
        }, []);
        this.virtualInPersonTypes = offers.reduce((prev, curr) => {
          this.offerType = curr.type;
          const virtualInPersonType = /online/.test(curr.location.type) ? 'online' : 'in-person';
          if (!prev.find((l) => l === virtualInPersonType)) {
            prev.push(virtualInPersonType);
          }
          return prev;
        }, []);
      }
    } else if (this.offer?.location) {
      this.locations = [this.offer?.location];
    }
  }
}

<!-- <div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       color="primary"
       href="//"
       id="get-hop"
       mat-flat-button>
      <mat-icon class="icon-sm ltr:mr-2 rtl:ml-2" svgIcon="mat:shopping_bag"></mat-icon>
      <span>Get Hop (Angular 14+)</span>
    </a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block test">
      Hop - Angular 14+ Material Design Admin Template - Save 100s of hours designing and coding
    </div>

  </div>
</div> -->

<div class="footer py-12 p-6" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" hopContainer class="container">
    <div class="flex flex-col space-y-16 items-center justify-between w-full md:flex-row md:space-y-0">
      <mat-icon [routerLink]="['/']" svgIcon="logo:logo" class="cursor-pointer icon-5xl hidden md:flex"></mat-icon>
      <div class="flex flex-col space-y-16 items-center md:items-start md:flex-row md:space-y-0 md:space-x-20"
        *ngIf="!(user$ | async) ">
        <!-- Col 1 -->
        <div class="flex flex-col items-center w-full md:items-start">
          <div class="mb-5 font-bold capitalize dark:">{{"_footer.company" | translate}}</div>
          <div class="flex flex-col items-center space-y-3 md:items-start">
            <a routerLink="/about-us" class="capitalize hover:text-cyan">{{"_footer.about-us" | translate}}</a>
            <a routerLink="/terms-and-conditions" class="capitalize hover:text-cyan">{{"_footer.terms" | translate}}</a>
            <a routerLink="/privacy" class="capitalize hover:text-cyan">{{"_footer.privacy" | translate}}</a>
          </div>
        </div>

        <div class="flex flex-col items-center w-full md:items-start">
          <div class="mb-5 font-bold capitalize">{{"_footer.features" | translate}}</div>
          <div class="flex flex-col items-center space-y-3 md:items-start">
            <a href="/@demo" class="capitalize hover:text-cyan">{{"_footer.demo" | translate}}</a>
            <a href="https://help.cosmoslevel.com/hc/manual/articles/1701525725-main-ad"
              class="capitalize hover:text-cyan">{{"_footer.main-features" | translate}}</a>
            <a href="https://help.cosmoslevel.com/hc/manual/articles/zoom-integration"
              class="capitalize hover:text-cyan">{{"_footer.zoom-integration" | translate}}</a>
          </div>
        </div>

        <div class="flex flex-col items-center w-full md:items-start">
          <div class="mb-5 font-bold capitalize">{{"_footer.links" | translate}}</div>
          <div class="flex flex-col items-center space-y-3 md:items-start">
            <a href="https://help.cosmoslevel.com" class="capitalize hover:text-cyan">{{"_footer.help" | translate}}</a>
            <a href="/pricing" class="capitalize hover:text-cyan">{{"_footer.pricing" | translate}}</a>
            <!--<a href="#" class="capitalize hover:text-cyan">{{"_footer.1-1-appointments" | translate}}</a>-->
            <a href="http://status.cosmoslevel.com" class="capitalize hover:text-cyan">{{"_footer.status" |
              translate}}</a>
          </div>
        </div>
      </div>

      <div class="flex space-x-1 md:space-x-6">
        <a mat-icon-button type="button">
          <mat-icon svgIcon="social:facebook"></mat-icon>
        </a>

        <a mat-icon-button type="button">
          <mat-icon svgIcon="social:instagram"></mat-icon>
        </a>

        <a mat-icon-button type="button">
          <mat-icon svgIcon="social:twitter"></mat-icon>
        </a>

        <hop-language-menu></hop-language-menu>
        <hop-button-theme-dark></hop-button-theme-dark>
        <button-help-chatwoot button-help-chatwoot></button-help-chatwoot>

      </div>
    </div>

    <!-- <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-sm>TopSejur Admin</div> -->
  </div>
</div>
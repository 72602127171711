import { AfterContentInit, AfterViewInit, Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@hop/config/config.service';
//import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { AuthenticationService, NavigationService, SplashScreenService } from '../@hop/services';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HopConfigName } from '../@hop/config/config-name.model';
import { ColorSchemeName } from '../@hop/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@hop/components/config-panel/color-variables';
import { Select, Store } from '@ngxs/store';
import { AppHopState, FeaturesState, LanguageSet, UserState } from '../@hop/state';
import { combineLatest, firstValueFrom, fromEvent, Observable } from 'rxjs';
import { FeatureEnum, FeaturesModel, UserModel } from '../@hop/models';
import { FeatureSetFromParams } from '../@hop/state';
import { AppState, AppStateModel } from './state/states/app-state.service';
import { debounceTime, filter } from 'rxjs/operators';
import { Language } from '../@hop/models/language-model';
import { TranslateService } from '@ngx-translate/core';
import { getDiff } from 'recursive-diff';
import { LanguageModel } from '../@hop/models/language.model';
import { AppUpdateService } from './services';
import { MatDialog } from '@angular/material/dialog';
import { DialogRegisterClientComponent } from './pages/pages/auth/dialog-register-client/dialog-register-client.component';
import { PublicProfileService } from '../@hop/services/public-profile.service';
import * as cookie from 'js-cookie';
import { SeoService } from 'src/@hop/services/seo.service';
import { WebsocketService } from '../@hop/services/websocket.service';
import { DialogProfileComponent } from '../@hop/components/dialog-profile/dialog-profile.component';
import { DialogAcceptTermsComponent } from 'src/@hop/components/accept-terms/dialog-accept-terms.component';
import { FingerprintService } from '../@hop/services/fingerprint.service';

@Component({
  selector: 'hop-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  @Select(AppState.selectColorScheme) app$: Observable<AppStateModel>;
  @Select(AppState.selectAppVersion) appVersion$: Observable<string>;
  @Select(AppState.selectAvailableLanguages) selectAvailableLanguages$: Observable<Language[]>;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  language = navigator.language;
  lastUrlWithoutQueryParams = '';
  private storeCurrent: any = {};
  appId = '';
  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    //@Inject(LOCALE_ID) private localeId: string,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private store: Store,
    private router: Router,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private splashScreenService: SplashScreenService,
    // we need this so the AppUpdateService is initialised
    private appUpdateService: AppUpdateService,
    private publicProfileService: PublicProfileService,
    private dialog: MatDialog,
    private seoService: SeoService,
    private fingerprintService: FingerprintService
  ) {
    this.store.subscribe((store) => {
      this.storeCurrent = store;
    });
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
      const currentUrlWithoutQueryParams = val.url.replace(/\?.+$/i, '');
      if (!/^.?login|register|onboard/.test(val.url)) {
        cookie.set('lastUrl', val.url);
      }
      if (this.lastUrlWithoutQueryParams !== currentUrlWithoutQueryParams) {
        this.lastUrlWithoutQueryParams = currentUrlWithoutQueryParams;
        // scroll to top
        setTimeout(() => {
          /*try {
            document.getElementsByTagName('mat-sidenav-content')[0].scrollTop = 0;
          } catch (e) {}*/
          window.scrollTo(0, 0);
        }, 100);
      }
    });
    //Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver((name: string, namespace: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
      switch (namespace) {
        case 'mat':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/material-design-icons/two-tone/${name}.svg`);

        case 'logo':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/logos/${name}.svg`);

        case 'flag':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/flags/${name}.svg`);

        case 'social':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/social/${name}.svg`);
      }
    });

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(async (queryParamMap) => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as HopConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }

      if (queryParamMap.has('lang')) {
        const availableLanguages = await firstValueFrom(this.selectAvailableLanguages$);
        const lang = availableLanguages.find((l) => l.code === queryParamMap.get('lang'));
        //console.log(lang);
        if (lang) {
          this.store.dispatch(new LanguageSet({ ...lang, isManuallySet: true }));
        }
      }
    });
    // this.translateService.get(['home', 'pricing', 'dashboard', 'new-link', 'help']).subscribe((translations) => {
    //   console.log(translations);
    // });
    combineLatest([
      this.user$,
      this.translateService.stream([
        '_general.profile',
        '_menu.dashboard',
        '_menu.content.menu-name',
        '_menu.content.services',
        '_menu.content.offer-appointments',
        '_menu.content.all-appointments',
        '_menu.content.all-client-appointments',
        '_menu.content.availability',
        '_menu.content.locations',
        '_menu.content.orders',
        '_menu.content.client-orders',
        '_menu.content.integrations',
        '_menu.content.connected-calendars',
        '_menu.new.menu-name',
        '_menu.new.new-offerAppointment',
        '_menu.new.new-service',
        '_menu.new.new-story',
        '_menu.new.new-page',
        '_menu.new.new-contact',
        '_menu.new.new-availability',
        '_menu.new.new-location',
        '_menu.reviews.submitted-reviews',
        '_menu.reviews.received-reviews',
        '_stories.stories',
        '_general.contact',
        '_pages.pages',
        '_contacts.contacts',
        'home',
        'pricing',
        'help',
        'login',
        '_menu.content.my-appointments'
      ]),
      this.publicProfileService.userProfile$,
      this.features$
    ])
      .pipe(debounceTime(100))
      .subscribe(([user, translations, userPublicProfile, features]) => {
        if (userPublicProfile?.user?.username) {
          this.navigationService.setUserPublicProfileMenu(user, translations, userPublicProfile);
        } else {
          this.navigationService.setAppMenu(user, translations, features);
        }
      });
  }

  @Select(AppHopState.selectLanguage) language$: Observable<LanguageModel>;

  ngAfterViewInit(): void {
    this.router.events.pipe(debounceTime(300)).subscribe((val) => {
      // console.log(window.location.pathname);
      let pageClasses = [];
      let allBodyClasses = this.document.querySelector('body').classList;
      allBodyClasses.forEach((className) => {
        if (className.includes('page-')) {
          pageClasses.push(className);
        }
      });
      if (pageClasses) {
        pageClasses.forEach((className) => {
          this.renderer.removeClass(this.document.body, className);
        });
      }
      this.renderer.addClass(this.document.body, 'page-' + window.location.pathname.replace('/', ''));
    });
  }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      if (
        Object.keys(params).reduce((prev, curr) => {
          if (!prev) {
            return !!/feature-/.test(curr);
          }
        }, false)
      ) {
        this.store.dispatch(new FeatureSetFromParams(params));
      }
    });
    this.authenticationService.loginPopup$.subscribe((type) => {
      this.dialog.open(DialogRegisterClientComponent, {
        panelClass: 'dialog-open-menu-action',
        data: {
          type: type
        }
      });
    });

    this.appId = await this.fingerprintService.getAppId();
    cookie.set('x-ray-id', this.appId);
  }

  async ngAfterContentInit() {
    let source$ = fromEvent<StorageEvent>(window, 'storage');
    source$.pipe(debounceTime(1000)).subscribe(async (data) => {
      try {
        const storeNew = JSON.parse(localStorage['@@STATE']);
        const diff = getDiff(this.storeCurrent, storeNew);
        if (diff.length) {
          console.log('reset store', diff);
          this.store.reset(storeNew);
          this.authenticationService.processAuthCookie().then();
        }
      } catch (e) {
        //console.log(e);
      }
    });
    // decide if we go on the dashboard or the home page
    //combine user$ and router.events and if we are on the home page, redirect to the dashboard
    combineLatest([this.user$, this.router.events.pipe(filter((event: NavigationStart) => event instanceof NavigationStart))])
      .pipe(debounceTime(300))
      .subscribe(([user, val]) => {
        if (user && val.url === '/') {
          this.router.navigate(['/dashboard']);
        }
      });

    this.user$.pipe(debounceTime(3000)).subscribe((user) => {
      if (user?.isGuest) {
        this.dialog.open(DialogProfileComponent, {
          panelClass: 'dialog-open',
          data: {
            user: user
          },
          disableClose: true
        });
      } else if (user?._needsToAcceptTerms && !user?.isGuest) {
        this.dialog.open(DialogAcceptTermsComponent, {
          panelClass: 'dialog-open-wide',
          data: { user: user },
          disableClose: true
        });
      }
    });
  }

  protected readonly FeaturesModel = FeaturesModel;
  protected readonly FeatureEnum = FeatureEnum;
}

import { Injectable } from '@angular/core';
import { addMinutes, differenceInMinutes, setHours, setMinutes } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { TimezoneModel } from '../models/timzone.model';

@Injectable({
  providedIn: 'root'
})
export class TimeUtilsService {
  getMinutes(dateStart: Date | string, dateEnd: Date) {
    return differenceInMinutes(new Date(dateEnd), new Date(dateStart));
  }

  generateTimeSlots(
    intervals: { dateStart: Date; dateEnd: Date }[],
    duration: number,
    timeSlotIncrement: number,
    buffer: { before: number; after: number },
    timezoneName = 'UTC'
  ) {
    const durationWithBuffer = duration + buffer?.before + buffer?.after;
    if (!timeSlotIncrement) {
      timeSlotIncrement = durationWithBuffer;
    }
    if (!timeSlotIncrement) return {};
    const timeSlots: { [date: string]: { times: string[] } } = {};
    for (const interval of intervals) {
      const startTimeWithBuffer = interval.dateStart.getTime() + buffer?.before * 60000;
      const endTime = interval.dateEnd.getTime();
      let currentTimeWithBeforeBuffer = startTimeWithBuffer;
      while (
        currentTimeWithBeforeBuffer == startTimeWithBuffer
          ? currentTimeWithBeforeBuffer + (duration + buffer.after) * 60 * 1000 <= endTime
          : currentTimeWithBeforeBuffer < endTime
      ) {
        const currentDate = this.convertToTimezone(new Date(currentTimeWithBeforeBuffer).toISOString(), timezoneName).replace(
          /(\d\d).(\d\d).(\d\d\d\d).+$/,
          '$3-$2-$1'
        );
        const currentTimeWithBeforeBufferString = this.convertToTimezone(new Date(currentTimeWithBeforeBuffer).toISOString(), timezoneName).replace(
          /^.+, ([\d:]+):\d\d$/,
          '$1'
        );
        if (!timeSlots[currentDate]) {
          timeSlots[currentDate] = { times: [] };
        }
        timeSlots[currentDate].times.push(currentTimeWithBeforeBufferString);
        currentTimeWithBeforeBuffer += timeSlotIncrement * 60 * 1000;
        if (currentTimeWithBeforeBuffer + (duration + buffer.after) * 60 * 1000 > endTime) {
          break;
        }
      }
    }
    return Object.keys(timeSlots).length === 0 ? timeSlots : this.completeIntervals(timeSlots);
  }

  convertToTimezone(isoDate: string, timezone: string): string {
    const date = new Date(isoDate);
    const options = { timeZone: timezone };
    return date.toLocaleString('ro-RO', options);
  }

  completeIntervals(intervals: { [date: string]: { times: string[] } }): { [date: string]: { times?: string[] } } {
    const intervalEntries = Object.entries(intervals);
    const startDate = new Date(intervalEntries[0][0]);
    const endDate = new Date(intervalEntries[intervalEntries.length - 1][0]);
    const completedIntervals: { [date: string]: { times?: string[] } } = {};
    let currentDate = new Date(startDate.getTime());
    currentDate.setUTCHours(0, 0, 0, 0);
    while (currentDate <= endDate) {
      const formattedDate = currentDate.toISOString().split('T')[0];
      if (!intervals[formattedDate]) {
        completedIntervals[formattedDate] = {};
      } else {
        completedIntervals[formattedDate] = intervals[formattedDate];
      }
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
    return completedIntervals;
  }

  transformAppointmentDateInFormattedDate(date, options: { language?: string; timezone?: string; showTimezone?: boolean } = { showTimezone: true }) {
    const dateObject = new Date(date);
    const timezone = options.timezone ? options.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
      (options.showTimezone ? timezone + ', ' : '') +
      dateObject.toLocaleString(options.language ? options.language : navigator.language, {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: timezone
      })
    );
  }

  formatDate(pastDateString: string | Date, translateService?: TranslateService): string {
    const pastDate = new Date(pastDateString ?? new Date());
    const date = new Date();
    const millisecondsPerMinute = 60 * 1000;
    const millisecondsPerHour = 60 * millisecondsPerMinute;
    const millisecondsPerDay = 24 * millisecondsPerHour;
    const millisecondsPerWeek = 7 * millisecondsPerDay;
    const millisecondsPerMonth = 30 * millisecondsPerDay;
    const millisecondsPerYear = 365 * millisecondsPerDay;

    const timeDifference = date.getTime() - pastDate.getTime();
    const absoluteTimeDifference = Math.abs(timeDifference);

    const timeUnitObject: {
      time: number;
      unit:
        | 'second'
        | 'seconds'
        | 'minute'
        | 'minutes'
        | 'hour'
        | 'hours'
        | 'week'
        | 'weeks'
        | 'month'
        | 'months'
        | 'day'
        | 'days'
        | 'year'
        | 'years';
      text?: string;
    } = {
      time: 0,
      unit: 'minutes',
      text: ''
    };

    if (absoluteTimeDifference < millisecondsPerMinute) {
      const seconds = Math.floor(absoluteTimeDifference / 1000);
      timeUnitObject.time = seconds;
      timeUnitObject.unit = seconds === 1 ? 'second' : 'seconds';
      // return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    } else if (absoluteTimeDifference < millisecondsPerHour) {
      const minutes = Math.floor(absoluteTimeDifference / millisecondsPerMinute);
      timeUnitObject.time = minutes;
      timeUnitObject.unit = minutes === 1 ? 'minute' : 'minutes';
    } else if (absoluteTimeDifference < millisecondsPerDay) {
      const hours = Math.floor(absoluteTimeDifference / millisecondsPerHour);
      timeUnitObject.time = hours;
      timeUnitObject.unit = hours === 1 ? 'hour' : 'hours';
    } else if (absoluteTimeDifference < millisecondsPerWeek) {
      const days = Math.floor(absoluteTimeDifference / millisecondsPerDay);
      timeUnitObject.time = days;
      timeUnitObject.unit = days === 1 ? 'day' : 'days';
    } else if (absoluteTimeDifference < millisecondsPerMonth) {
      const weeks = Math.floor(absoluteTimeDifference / millisecondsPerWeek);
      timeUnitObject.time = weeks;
      timeUnitObject.unit = weeks === 1 ? 'week' : 'weeks';
    } else if (absoluteTimeDifference < millisecondsPerYear) {
      const months = Math.floor(absoluteTimeDifference / millisecondsPerMonth);
      timeUnitObject.time = months;
      timeUnitObject.unit = months === 1 ? 'month' : 'months';
    } else {
      const years = Math.floor(absoluteTimeDifference / millisecondsPerYear);
      timeUnitObject.time = years;
      timeUnitObject.unit = years === 1 ? 'year' : 'years';
    }
    return translateService.instant('_general._time-unit.' + timeUnitObject.unit + '_ago', { value: timeUnitObject.time });
  }

  transformMinutes(minutes, translateService?: TranslateService): { time: number; unit: string; text?: string } {
    const timeUnitObject: { time: number; unit: 'minute' | 'minutes' | 'hour' | 'hours' | 'day' | 'days'; text?: string } = {
      time: 0,
      unit: 'minutes',
      text: ''
    };
    if ((minutes / 1440) % 1 === 0) {
      timeUnitObject.time = minutes / 1440;
      timeUnitObject.unit = minutes / 1440 > 1 ? 'days' : 'day';
    } else if ((minutes / 60) % 1 === 0) {
      timeUnitObject.time = minutes / 60;
      timeUnitObject.unit = minutes / 60 > 1 ? 'hours' : 'hour';
    } else {
      timeUnitObject.time = minutes;
      timeUnitObject.unit = minutes > 1 ? 'minutes' : 'minute';
    }

    timeUnitObject.text = timeUnitObject.time + ' ' + timeUnitObject.unit;
    if (translateService) {
      timeUnitObject.text = timeUnitObject.time + ' ' + translateService.instant('_general._time-unit.' + timeUnitObject.unit);
    }
    return timeUnitObject;
  }

  transformInArrayDateFormat(dateStringFormat, timezone?): any {
    const dateString = dateStringFormat;
    const dateObject = new Date(dateString);
    const localDateString = dateObject.toLocaleString('ro-RO', {
      timeZone: timezone ? timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const [date, time] = localDateString.split(', ');
    const [day, month, year] = date.split('.');
    const [hour, minute] = time.split(':');
    return [Number(year), Number(month), Number(day), Number(hour), Number(minute)];
  }

  transformInDateFormatToAddToGoogleCalendar(date) {
    const transformedDate = date.replace(/[:-]/g, '').replace(/\.\d\d\dZ/, 'Z');
    return transformedDate;
  }

  generateURLWithQueryParams(url: string, params: any): string {
    const queryParams = new URLSearchParams(params).toString();
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}${queryParams}`;
  }

  createGoogleLinkAppointment(bookedAppointment) {
    const params = {
      text: bookedAppointment?.summary,
      details: bookedAppointment?.description,
      trp: true,
      sf: true,
      location: bookedAppointment.appointmentDetails.location.value,
      dates: `${this.transformInDateFormatToAddToGoogleCalendar(bookedAppointment?.dateStart)}/${this.transformInDateFormatToAddToGoogleCalendar(
        bookedAppointment?.dateEnd
      )}`
    };
    const generatedUrl = this.generateURLWithQueryParams('https://calendar.google.com/calendar/u/0/r/eventedit', params);
    return generatedUrl;
  }

  _convertSelectedTimeAndSelectedDateToUtc(hourMinutes: string, selectedDate: Date, timezoneName, addMinutesToDate = 0): Date {
    const selectedDateWithHour = selectedDate.toDateString() + ', ' + hourMinutes;
    const [date, time] = selectedDateWithHour.split(', ');
    const [hour, minute] = time.split(':');
    const utcOffset = new Date(selectedDateWithHour).toLocaleTimeString('en-US', { timeZone: timezoneName, timeZoneName: 'short' }).split(' ')[2];
    const formattedDate = addMinutes(new Date(`${date} ${hour}:${minute}:00 ${utcOffset}`), addMinutesToDate).toISOString();
    return new Date(formattedDate);
  }

  convertSelectedTimeAndSelectedDateToUtc(hourMinutes: string, selectedDate: Date, timezoneName: string, addMinutesToDate = 0): Date {
    const [hour, minute] = hourMinutes.split(':').map(Number);

    // Create a new date with the provided hours and minutes
    let dateWithTime = setHours(selectedDate, hour);
    dateWithTime = setMinutes(dateWithTime, minute);

    // Add the additional minutes if specified
    if (addMinutesToDate !== 0) {
      dateWithTime = addMinutes(dateWithTime, addMinutesToDate);
    }

    // Convert the time to the specified timezone and get the UTC equivalent
    const utcDate = zonedTimeToUtc(dateWithTime, timezoneName);

    return utcDate;
  }

  localTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getWeekIntervals(dateIntervals: DateInterval[], timezone: TimezoneModel): AvailabilityByWeek {
    // Initialize a map to hold intervals grouped by week start date
    const weeksMap: { [key: string]: WeekDays } = {};

    dateIntervals.forEach((interval) => {
      // Convert dateStart to the specified timezone
      const zonedDateStart = utcToZonedTime(interval.dateStart, timezone.name);
      const weekStart = new Date(zonedDateStart);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Set to the start of the week (Sunday)
      const weekStartStr = weekStart.toISOString().split('T')[0]; // Convert to string for map key

      if (!weeksMap[weekStartStr]) {
        weeksMap[weekStartStr] = {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        };
      }

      const dayOfWeek = zonedDateStart.getDay();
      const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      const dayName = dayNames[dayOfWeek];
      weeksMap[weekStartStr][dayName].push(interval);
    });

    return weeksMap;
  }
}
export interface DateInterval {
  dateStart: Date;
  dateEnd: Date;
  summary?: string;
  id?: number;
}

export interface TimeSlotInterval {
  [propKey: string]: { times: string[] };
}

type AvailabilityByWeek = {
  [key: string]: WeekDays;
};
interface WeekDays {
  monday: DateInterval[];
  tuesday: DateInterval[];
  wednesday: DateInterval[];
  thursday: DateInterval[];
  friday: DateInterval[];
  saturday: DateInterval[];
  sunday: DateInterval[];
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription, debounceTime } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { FeaturesState } from '../../state';
import { FeaturesModel } from '../../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
/*
*
<div>Using template:</div>
<ng-template
  [ifFeature]="'myFeature'"
  [ifFeatureElse]="featureFlagIsOff"
>
  <div>ON flag is ON</div>
</ng-template>
<ng-template #featureFlagIsOff><div>OFF flag is OFF</div></ng-template>
<div>Using shorthand</div>

<div *ifFeature="'myFeature';else featureFlagIsOff1">ON flag is ON</div>
<ng-template #featureFlagIsOff1><div>OFF flag is OFF</div></ng-template>

<button (click)="onToggleFlag()">Toggle!</button>

*
* */
@UntilDestroy()
@Directive({
  //imports: [CommonModule],
  standalone: true,
  selector: '[ifFeature]'
})
export class IfFeatureFlagDirective<IfTemplate, ElseTemplate> {
  //constructedForFlag?: string;
  elseTemplate?: TemplateRef<ElseTemplate>;
  flag?: string;
  prevValue = null;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  private s?: Subscription;

  private _variant: string;
  @Input('ifFeatureVariant')
  set ifFeatureVariant(value: string) {
    this._variant = value;
    console.log('_variant: ', value);
  }

  constructor(private template: TemplateRef<IfTemplate>, private viewContainerRef: ViewContainerRef, private store: Store) {}
  // featureVariant;

  @Input('ifFeature')
  set ifFeatureFlag(flag: string) {
    this.flag = flag;
    this.updateView();
  }
  // @Input('featureVariant')
  // set setFeatureVariant(featureVariant: string) {
  //   this.featureVariant = featureVariant
  // }

  @Input('ifFeatureElse')
  set ifFeatureFlagNotPresent(t: TemplateRef<ElseTemplate>) {
    this.elseTemplate = t;
  }

  private updateView() {
    if (this.flag == null || this.s != null) {
      return;
    }

    this.s = this.features$.pipe(debounceTime(100), untilDestroyed(this)).subscribe((features) => {
      let on: { value: boolean | string; payload: string } = features ? features[this.flag] : null;
      if (this.prevValue === on?.value) {
        return;
      }
      this.viewContainerRef.clear();
      if (typeof on?.value === 'boolean') {
        if (on.value) {
          this.viewContainerRef.createEmbeddedView(this.template);
          //this.constructedForFlag = this.flag;
        } else if (this.elseTemplate != null) {
          this.viewContainerRef.createEmbeddedView(this.elseTemplate);
          //this.constructedForFlag = this.flag;
        } else {
          this.viewContainerRef.clear();
          //this.constructedForFlag = undefined;
        }
      } else {
        if (on?.value && on?.value === this._variant) {
          this.viewContainerRef.createEmbeddedView(this.template);
          //this.constructedForFlag = this.flag;
        } else {
          this.viewContainerRef.clear();
        }
      }
      this.prevValue = on?.value;
    });
  }

  ngOnDestroy() {
    if (typeof this.s?.unsubscribe === 'function') {
      this.s.unsubscribe();
    }
  }
}

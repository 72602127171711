import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { OrderProduct } from 'src/@hop/models/order-product.model';
import { OrderModel } from 'src/@hop/models/order.model';
import { LayoutService } from 'src/@hop/services';
import { OrdersService } from 'src/@hop/services/orders.service';
import { SubscriptionWebsocketResponse } from '../../services/websocket.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'hop-basket-view',
  templateUrl: './basket-view.component.html',
  styleUrls: ['./basket-view.component.scss']
})
export class BasketViewComponent implements OnInit {
  order: OrderModel;
  products: OrderProduct[];
  username: string;

  constructor(public layoutService: LayoutService, private route: ActivatedRoute, private router: Router, private ordersService: OrdersService) {
    router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((val: NavigationStart) => {
      this.layoutService.closeBasket();
    });
  }

  ngOnInit(): void {
    this.ordersService.cart$.subscribe((order) => {
      this.order = order;
      this.products = order?.orderProducts;
    });
  }

  async getBasket() {
    this.ordersService.fromWebsocketCart$.next(this.ordersService.fromWebsocketCart$.value);
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserModel } from '../../@hop/models';
import { Actions, Select, Store } from '@ngxs/store';
import { UserState } from '../../@hop/state';
import { fromEvent, Observable, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogSupportEmailComponent } from 'src/@hop/components/dialog-support-email/dialog-support-email.component';

type ReqRespType = {
  request: HttpRequest<any>;
  response: HttpResponse<any>;
};

@Injectable({
  providedIn: 'root'
})
export class ChatWootService {
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  chatwootReady$;
  constructor(private actions$: Actions, private dialog: MatDialog) {
    // this doesn't work because angular loads too slow to catch the event
    /*window.addEventListener('chatwoot:ready', function () {
      debugger;
    });*/
    //this.chatwootReady$ = fromEvent<any>(window, 'chatwoot:ready');
    combineLatest([this.user$])
      .pipe(debounceTime(3000))
      // @ts-ignore
      .subscribe(([user]) => {
        const chatwootLoaded = window._get('$chatwoot.hasLoaded', false);
        // @ts-ignore
        if (user && chatwootLoaded) {
          console.log('set chatwoot user');
          // @ts-ignore
          window.$chatwoot.setUser(user.email || user.phone, {
            email: user.email,
            name: user.firstName + ' ' + user.lastName,
            avatar_url: user.imageFile?.url,
            phone_number: user.phone,
            identifier_hash: user._identifier_hash, // Identifier Hash generated based on the webwidget hmac_token
            description: '', // description about the user
            country_code: user.country?.code // Two letter country code
            //city: '', // City of the user
            //company_name: '', // company name
            /*social_profiles: {
            twitter: '', // Twitter user name
            linkedin: '', // LinkedIn user name
            facebook: '', // Facebook user name
            github: '' // Github user name
          }*/
          });
        } else {
          if (!chatwootLoaded) {
            console.warn('chatwoot not loaded');
          } else if (!user) {
            // @ts-ignore
            // @ts-ignore
            if (chatwootLoaded) {
              // @ts-ignore
              window.$chatwoot.reset();
            }
          }
        }
      });
  }

  toggleBubbleVisibility(visibility: 'show' | 'hide') {
    // @ts-ignore
    if (window && window?.$chatwoot && window?.$chatwoot?.hasLoaded) {
      // @ts-ignore
      window.$chatwoot?.toggleBubbleVisibility(visibility);
    }
  }
  openChatWoot() {
    // @ts-ignore
    if (window && window?.$chatwoot && window?.$chatwoot?.hasLoaded) {
      // @ts-ignore
      window.$chatwoot.toggle('show');
    } else {
      this.dialog.open(DialogSupportEmailComponent, {
        panelClass: 'dialog-open-menu-action',

      });

      // open dialog to contact us at support@...

    }
  }
}

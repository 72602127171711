import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Environment, UserProfileModel } from '../models';
import { PageModel } from '../models/pageModel';
import { ProductModel } from '../models/product.model';
import { OfferModel } from '../models/offer.model';
import { TranslateService } from '@ngx-translate/core';
import { PublicProfileModel } from '../models/public-profile.model';
import { PublicProfileService } from './public-profile.service';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';
import { combineLatest, debounceTime, filter, firstValueFrom } from 'rxjs';
import { extractFirstNWords } from './utils';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private sanitizer: DomSanitizer,
    @Inject('environment') public environment: Environment,
    private translateService: TranslateService,
    private publicProfileService: PublicProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    combineLatest([this.publicProfileService.userProfile$, this.router.events.pipe(filter((event) => event instanceof NavigationStart))])
      .pipe(debounceTime(300))
      .subscribe((data) => {
        const publicProfile = data[0];
        //@ts-ignore
        const url = data[1]?.url?.replace(/\?.+$/i, '');
        if (publicProfile?.user?.username && url) {
          const regex = new RegExp(`^\/@${publicProfile.user.username}\/(p|stories)$`, 'is');
          if (url.match(regex)) {
            const name = this.translateService.instant('_seo-router._' + url.match(regex)[1] + '.name');
            if (regex.test(url)) {
              this.setGeneralPublicProfile(publicProfile, url, name);
            }
          }
        }
      });
  }

  // Function to Set title
  setTitle(title: string) {
    this.titleService.setTitle(title + ' - ' + environment.name.replace(/.com$/, ''));
  }

  // Function to set meta tags
  setMetaTags(tags: { [propName: string]: any }) {
    Object.keys(tags).forEach((key) => {
      this.metaService.updateTag({ name: key, content: tags[key] });
    });
  }
  updateOGTags(title: string, description: string, image: string, url: string, type: string): void {
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:type', content: type });
    this.metaService.updateTag({ property: 'og:image', content: image });
    this.metaService.updateTag({ property: 'og:image:width', content: '300' });
    this.metaService.updateTag({ property: 'og:image:height', content: '300' });
    this.metaService.updateTag({ property: 'og:image:type', content: 'image/jpeg' });
    this.metaService.updateTag({ property: 'og:url', content: url });
    this.metaService.updateTag({ property: 'og:description', content: extractFirstNWords(description, 50) });
    this.metaService.updateTag({ property: 'og:site_name', content: this.environment.mainUrl.replace(/https?:\/\//, '') });
  }

  updateTwitterTags(title: string, description: string, image: string, url: string, type: string): void {
    const username = this.extractUsername(window.location.href);
    this.metaService.updateTag({ name: 'twitter:card', content: type });
    this.metaService.updateTag({
      name: 'twitter:site',
      content: username ?? '@cosmoslevel'
    });
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:description', content: extractFirstNWords(description, 30) });
    this.metaService.updateTag({ name: 'twitter:image:src', content: image });
    this.metaService.updateTag({ name: 'twitter:app:url:iphone', content: url });
    this.metaService.updateTag({ name: 'twitter:app:url:ipad', content: url });
  }

  // Optionally, add other OG properties (type, site_name, etc.)

  // Function to set canonical URL
  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.getOrCreateLinkForCanonical();
    if (/^\//.test(url)) {
      url = this.environment.mainUrl + url;
    }
    if (!url) {
      link.setAttribute('rel', '');
      link.setAttribute('href', '');
    } else {
      //let safeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', url); // Ignoring sanitizer warning by typecasting safeUrl to string.
    }
  }

  // Function to get or create the canonical link element
  private getOrCreateLinkForCanonical(): HTMLLinkElement {
    const selector = 'link[rel="canonical"]';
    let link = document.querySelector(selector);
    if (link === null) {
      link = document.createElement('link');
      document.head.appendChild(link);
    }
    return link as HTMLLinkElement;
  }

  setPage(page: PageModel) {
    //console.log('setPage');
    this.setCanonicalURL(page._uri);
    const username = this.extractUsername(window.location.href);
    if (username && page.user) {
      this.setTitle(`${page.title} - ${page.user.firstName} ${page.user.lastName}`);
    } else {
      // this is a cosmoslevel page
      this.setTitle(page.title);
    }

    this.setMetaTags({
      description: page.excerpt,
      keywords: page.manyTags?.reduce((prev, curr) => {
        return prev + (prev ? ',' : '') + curr.name;
      }, '')
    });
    this.updateOGTags(page.title, page.excerpt, page.imageFile?.url, window.location.href, 'article');
    this.updateTwitterTags(page.title, page.excerpt, page.imageFile?.url, window.location.href, 'article');
  }

  setProduct(product: ProductModel) {
    this.setCanonicalURL(product._uri);
    this.setTitle(`${product.name} - ${product.user.firstName} ${product.user.lastName}`);
    this.setMetaTags({ description: extractFirstNWords(product.description, 100) });
    this.updateOGTags(product.name, product.description, product.imageFile.url, window.location.href, 'website');
    this.updateTwitterTags(product.name, product.description, product.imageFile.url, window.location.href, 'website');
  }
  setGeneralPublicProfile(publicProfile: PublicProfileModel, url: string, name: string) {
    this.setCanonicalURL(url);
    const title = `${publicProfile.user.firstName} ${publicProfile.user.lastName} - ${name}`;
    this.setTitle(title);
    this.setMetaTags({ description: name });
    this.updateOGTags(title, name, publicProfile.imageFile?.url, window.location.href, 'website');
    this.updateTwitterTags(title, name, publicProfile.imageFile?.url, window.location.href, 'website');
  }

  setOffer(offer: OfferModel) {
    this.setCanonicalURL(offer._uri);
    this.setTitle(`${offer.name} - ${offer.user.firstName} ${offer.user.lastName}`);
    this.setMetaTags({ description: extractFirstNWords(offer.description, 100) });
    this.updateOGTags(offer.name, offer.description, offer.imageFile.url, window.location.href, 'website');
    this.updateTwitterTags(offer.name, offer.description, offer.imageFile.url, window.location.href, 'website');
  }
  setPublicProfile(publicProfile: UserProfileModel) {
    this.setCanonicalURL('/@' + publicProfile.user.username);
    const title = `${publicProfile.user.firstName} ${publicProfile.user.lastName} - ${this.translateService.instant('_general.profile')}`;
    this.setTitle(title);
    this.setMetaTags({ description: publicProfile.bio });
    this.updateOGTags(title, publicProfile.bio, publicProfile.imageFile?.url, window.location.href, 'website');
    this.updateTwitterTags(title, publicProfile.bio, publicProfile.imageFile?.url, window.location.href, 'website');
  }

  extractUsername(url) {
    const parts = url.split('/');
    for (let i = 0; i < parts.length; i++) {
      if (parts[i][0] === '@') {
        return parts[i].split('?')[0];
      }
    }
    return '';
  }
}

<div class="">
  <div class="flex justify-between">
    <h1 mat-dialog-title class="text-lg">{{ 'edit-profile' | translate }}</h1>
    <div mat-dialog-actions *ngIf="!user?.isGuest">
      <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
    </div>
  </div>
  <div mat-dialog-content>
    <hop-names (formSubmit)="submit($event)"></hop-names>
  </div>
</div>
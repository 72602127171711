<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-0 sm:px-4 flex items-center">
  <button [matMenuTriggerFor]="menu" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <!-- <ng-container class="m-12" *ngFor="let item of navigationItems">
      <button [routerLink]="item?.route" mat-menu-item>
        <mat-icon [svgIcon]="item?.icon"></mat-icon>
        <span>{{ item?.label }}</span>
      </button>
    </ng-container> -->
    <hop-sidenav-item *ngFor="let item of navigationService.items$ | async; trackBy: trackByRoute" [item]="item"
      [level]="0"></hop-sidenav-item>
  </mat-menu>

  <a *ngIf="(isVerticalLayout$ | async) && !userPublicProfile && !isProfessionalHomeRoute"
    [routerLink]="[(user$ | async) ? '/dashboard' : '/']" class="ltr:mr-4 rtl:ml-4  flex items-center ">
    <mat-icon svgIcon="logo:logo"></mat-icon>
    <h1 class="title pl-1 sm:pl-4 m-0 select-none">{{("_brand."+(brand$|async)+".name") | translate}}</h1>
  </a>

  <a *ngIf="(isVerticalLayout$ | async) && userPublicProfile?.user && !isProfessionalHomeRoute"
    [routerLink]="['/@'+userPublicProfile?.user?.username]" class="ltr:mr-4 rtl:ml-4  flex items-center ">
    <img [src]='userPublicProfile?.user?.imageFile?.url' alt='' class='w-9 h-9 rounded-full'>
    <h1 class="title pl-1 xs:pl-4 m-0 select-none max-w-32 xs:max-w-48 sm:max-w-72 xs:overflow-x-hidden">
      {{userPublicProfile?.user?.firstName}} {{userPublicProfile?.user?.lastName}}</h1>
  </a>

  <!--<button *ngIf="isHorizontalLayout$ | async"
          [class.hidden]="mobileQuery"
          [matMenuTriggerFor]="addNewMenu"
          color="primary"
          mat-flat-button
          type="button">Add New
  </button>-->

  <!--<mat-menu #addNewMenu="matMenu" [overlapTrigger]="false" xPosition="after" yPosition="below">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:local_offer"></mat-icon>
      <span>Add Contact</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:done_all"></mat-icon>
      <span>Add Task</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment_turned_in"></mat-icon>
      <span>Add Project</span>
    </button>

    <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button>
  </mat-menu>-->

  <!--<mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>-->

  <!--<div #megaMenuOriginRef class="ltr:ml-2 rtl:mr-2">
    <button *ngIf="isHorizontalLayout$ | async"
            [class.hidden]="mobileQuery"
            (click)="openMegaMenu(megaMenuOriginRef)"
            [ngClass]="{ 'bg-primary/10': megaMenuOpen$ | async }"
            color="primary"
            mat-button
            type="button">
      <span>Mega Menu</span>
      <mat-icon class="icon-sm" svgIcon="mat:arrow_drop_down"></mat-icon>
    </button>
  </div>-->

  <span class="flex-1" *ngIf='!userPublicProfile'></span>
  <div class="-mx-1 flex items-center" *ngIf='!userPublicProfile'>
    <!--<div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>-->

    <!--<div class="px-1">
      <hop-toolbar-notifications></hop-toolbar-notifications>
    </div>-->

    <!-- <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div> -->

    <hop-button-theme-dark class="hidden sm:block"></hop-button-theme-dark>

    <hop-language-menu></hop-language-menu>
  </div>

  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center">
    <hop-navigation-item *ngFor="let item of navigationService.items$ | async" [item]="item"></hop-navigation-item>
  </div>


  <span class="flex-1" *ngIf='userPublicProfile'></span>

  <div class="-mx-1 flex items-center" *ngIf='userPublicProfile'>
    <!--<div class="px-1">
      <button (click)="openSearch()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
      </button>
    </div>-->

    <!--<div class="px-1">
      <hop-toolbar-notifications></hop-toolbar-notifications>
    </div>-->

    <!-- <div class="px-1">
      <button (click)="openQuickpanel()" mat-icon-button type="button">
        <mat-icon color="primary" svgIcon="mat:bookmarks"></mat-icon>
      </button>
    </div> -->

    <hop-button-theme-dark class="hidden"></hop-button-theme-dark>

    <hop-language-menu class="hidden sm:block"></hop-language-menu>

    <button *ngIf="cartCount > 0" (click)="openBasket()" mat-icon-button>
      <mat-icon svgIcon="mat:shopping_cart" [matBadge]="cartCount > 0 ? cartCount : null"
        matBadgeColor="warn"></mat-icon>
    </button>

  </div>

  <!--<a *ngIf="!user && !userPublicProfile" routerLink="/register" color="primary" mat-raised-button>{{ 'sign-in' |
    translate }}</a>-->
  <a *ngIf="!user" (click)='authenticationService.loginPopup$.next("register")' color="primary" mat-raised-button>{{
    'sign-in' | translate }}</a>

  <div *ngIf="user" class="px-1">
    <hop-toolbar-user [user]="user"></hop-toolbar-user>
  </div>
</div>

<!--<hop-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></hop-navigation>-->
<div
  *ngIf="!acceptedCookiePolicy"
  class="w-full rounded-md p-6 sm:mr-4 sm:mb-4 sm:max-w-sm bg-black fixed bottom-0 z-50 text-xs flex flex-col space-y-4"
>
  <p style="color: white !important" class="text-sm">
    {{"_cookies.this-website-uses-cookies"|translate}}
    <a class="underline" href="https://www.cookiesandyou.com/" target="_blank">{{"_general.learn-more"|translate}}</a>
  </p>
  <button (click)="acceptCookie()" mat-raised-button color="primary" data-testid='button-cookie-confirm'>{{"_general.got-it"|translate}}</button>
</div>

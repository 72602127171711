import { UserAuth } from 'src/@hop/models/user-auth.model';
import { UserModel } from '../../models';

export class UserGet {
  static readonly type = '[User] Get';
}
export class UserUpdate {
  static readonly type = '[User] Update';
  constructor(public user: UserModel) {}
}

export class UserLogin {
  static readonly type = '[User] Login';
  constructor(public email: string, public password: string, public rememberMe: boolean) {}
}

export class UserLogout {
  static readonly type = '[User] Logout';
  constructor() {}
}

export class UserEdit {
  static readonly type = '[User] Edit';
  constructor(public user: any) {}
}

<div *ngIf='form' [formGroup]="form" class="flex flex-col space-y-4 ">
  <div class="flex flex-col space-y-20">
    <h1 class="text-xl justify-center">{{'_general.set-password-for-account'|translate}}</h1>
  </div>

  <div class="flex flex-col">
    <mat-form-field class="w-full">
      <mat-label>{{ 'password' | translate }}</mat-label>
      <input [type]="inputType" formControlName="newPassword" matInput />
      <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
        <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
        <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
      </button>
      <!--<mat-hint>{{ 'passwordHint' | translate }}</mat-hint>-->
      <mat-error>
        <hop-mat-form-error control="newPassword"></hop-mat-form-error>
      </mat-error>
    </mat-form-field>

    <password-strength-meter [password]="f['newPassword']?.value" [minPasswordLength]="8"></password-strength-meter>
    <mat-form-field class="w-full">
      <mat-label>{{ 'passwordConfirm' | translate }}</mat-label>
      <input [type]="inputType" formControlName="passwordConfirm" matInput required />
      <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
        <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
        <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
      </button>
      <!--<mat-hint>{{ 'passwordHint' | translate }}</mat-hint>-->
      <mat-error>
        <hop-mat-form-error control="passwordConfirm"></hop-mat-form-error>
      </mat-error>
    </mat-form-field>
    <mat-error
      *ngIf="form.get('newPassword')?.touched && form.get('passwordConfirm')?.touched && form.hasError('notSame')">
      {{"_authentication.passwords-do-not-match" | translate }}
    </mat-error>
  </div>
  <div class="self-end">
    <a (click)="submit()" class="w-full md:w-40" color="primary" mat-raised-button
      [disabled]="!form.dirty || !form.valid">{{"_general.save" | translate }}</a>
  </div>
</div>
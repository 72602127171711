<!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<div class="stickyDebug" *ifFeature="'isDebug'">
  <div class="debugTool" *ngIf="activate">
    <button mat-flat-button color="warn" [matMenuTriggerFor]="aboveMenu">Features</button>
    <mat-menu #aboveMenu="matMenu" yPosition="above">
      <section *ngFor="let value of features$ | async | keyvalue" class="example-section--column">
        <mat-checkbox [checked]="value.value" (click)="setFeatureValue(value.key, !value.value)" class="example-margin">{{
          value.key
        }}</mat-checkbox>
      </section>
    </mat-menu>
  </div>

  <div>
    <button (click)="openMenuUser()" mat-fab color="primary"><mat-icon>build</mat-icon></button>
  </div>
</div> -->

<div class="cursor-pointer fixed right-1 top-4 sm:right-3 sm:top-10 md:right-6 z-50 ">
  <a (click)="toggleMenu()" [ngClass]="{'active': isMenuActive}" class="toggle-menu">
    <i></i>
    <i></i>
    <i></i>
  </a>
</div>
import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Select } from '@ngxs/store';
import { FeaturesState, UserState } from '../../../../@hop/state';
import { Observable } from 'rxjs';
import { FeatureEnum, FeaturesModel, UserModel } from '../../../../@hop/models';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as cookie from 'js-cookie';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ValueAccessorDirective } from '../../../../@hop/_helpers/value-accessor.directive';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FastSignInEmailComponent } from './fast-sign-in-email.component';
import { FastSignInPhoneComponent } from './fast-sign-in-phone.component';
import { MatTabsModule } from '@angular/material/tabs';
import { IfFeatureFlagDirective } from '../../../../@hop/components/is-feature/is-feature.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    TranslateModule,
    FastSignInEmailComponent,
    FastSignInPhoneComponent,
    MatTabsModule,
    IfFeatureFlagDirective,
    MatButtonToggleModule,
    FormsModule
  ],
  selector: 'hop-fast-sign-in-global',
  template: `
    <div *ngIf="stateCtrl.value" class="space-y-4 flex flex-col">
      <p *ngIf="stateCtrl.value.email" data-testid="hop-fast-sign-in-global-email">{{ '_general.email' | translate }}: {{ stateCtrl.value.email }}</p>
      <p *ngIf="stateCtrl.value.phone" data-testid="hop-fast-sign-in-global-email">{{ '_general.phone' | translate }}: {{ stateCtrl.value.phone }}</p>
      <p data-testid="hop-fast-sign-in-global-name">
        {{ '_general.name' | translate }}: {{ stateCtrl.value.firstName }} {{ stateCtrl.value.lastName }}
      </p>
    </div>
    <div *ngIf="!stateCtrl.value" class="space-y-4 flex flex-col" aria-label="Choose Login Method">
      <div class="space-y-4 flex flex-col" *ngIf="!defaultLoginMethod">
        <a (click)="goToGoogleClientAuth()" target="_blank" class="cursor-pointer flex border-2 rounded-md py-2 items-center justify-center">
          <mat-icon style="width: 50px; height: 30px" svgIcon="logo:google"></mat-icon>
          <p>{{ '_account._login.continue-with-google' | translate }}</p>
        </a>
        <a
          *ngIf="defaultLoginMethod != 'email'"
          (click)="loginWithEmail()"
          target="_blank"
          class="cursor-pointer flex border-2 rounded-md py-2 items-center justify-center"
        >
          <mat-icon style="width: 50px; height: 30px">email</mat-icon>
          <p>{{ '_account._login.continue-with-email' | translate }}</p>
        </a>

        <a
          *ngIf="defaultLoginMethod != 'phone' && (features$ | async).isPhone.value"
          (click)="loginWithPhone()"
          target="_blank"
          class="cursor-pointer flex border-2 rounded-md py-2 items-center justify-center"
        >
          <mat-icon style="width: 50px; height: 30px">phone</mat-icon>
          <p>{{ '_account._login.continue-with-phone' | translate }}</p>
        </a>
      </div>
      <div class="w-full flex flex-row justify-center items-center" *ngIf="defaultLoginMethod">
        <mat-button-toggle-group
          name="loginMethod"
          aria-label="Choose Login Method"
          (ngModelChange)="modelChanged($event)"
          [(ngModel)]="defaultLoginMethod"
        >
          <mat-button-toggle value="google">
            <mat-icon svgIcon="logo:google"></mat-icon>
            Google
          </mat-button-toggle>
          <mat-button-toggle value="email">
            <mat-icon>email</mat-icon>
            {{ '_label.email' | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="phone" *ngIf="(features$ | async).isPhone.value">
            <mat-icon>phone</mat-icon>
            {{ '_label.phone' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="defaultLoginMethod" class="items-center justify-center w-full hidden sm:inline-flex">
        <hr class="w-64 h-1 my-2 bg-gray-100 border-0 rounded dark:bg-gray-500" />
        <!--<div class="absolute px-4 -translate-x-1/2 bg-foreground left-1/2 dark:bg-gray-500">
          {{ '_general.OR' | translate }}
        </div>-->
      </div>
      <hop-fast-sign-in-email [isProfessional]="isProfessional" #emailFastLoginRef *ngIf="defaultLoginMethod === 'email'"></hop-fast-sign-in-email>
      <hop-fast-sign-in-phone [isProfessional]="isProfessional" #phoneFastLoginRef *ngIf="defaultLoginMethod === 'phone'"></hop-fast-sign-in-phone>
    </div>
  `,
  //styleUrls: ['./user-fast-login.component.scss'],
  hostDirectives: [ValueAccessorDirective]
})
export class FastSignInGlobalComponent implements OnInit {
  @Input() readOnly = false;
  @Input() defaultLoginMethod: 'email' | 'phone' = null;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  @ViewChildren('emailFastLoginRef') loginEmailRef: QueryList<FastSignInEmailComponent>;
  @ViewChildren('phoneFastLoginRef') loginPhoneRef: QueryList<FastSignInPhoneComponent>;
  @Input() isProfessional = false;
  constructor(public valueAccessor: ValueAccessorDirective<UserModel>) {
    this.valueAccessor.validate = (control?: AbstractControl<any, any>): ValidationErrors => {
      if (!this.stateCtrl.value) {
        return { required: true };
      } else {
        return null;
      }
    };
    this.valueAccessor.value$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.stateCtrl.setValue(user);
    });
  }
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  @ViewChild('emailStatusControl') emailStatusControl?: ElementRef;
  stateCtrl: UntypedFormControl;

  ngOnInit() {
    this.stateCtrl = new UntypedFormControl();
    this.user$.pipe(debounceTime(100), untilDestroyed(this)).subscribe((user) => {
      if (!this.readOnly) {
        setTimeout(() => {
          //console.log('user set');
          this.stateCtrl.setValue(user);
          this.valueAccessor.valueChange(user);
        }, 0);
      }
    });
  }

  protected readonly environment = environment;

  goToGoogleClientAuth() {
    cookie.set('auth-close', 'true', {
      expires: 0.01
    });
    window.open(this.environment.mainUrl + '/api/authorization/google-client', '_blank');
  }

  protected readonly FeatureEnum = FeatureEnum;

  loginWithEmail() {
    this.defaultLoginMethod = 'email';
    setTimeout(() => {
      this.loginEmailRef.first.emailStatusControl?.nativeElement.focus();
    }, 100);
  }

  loginWithPhone() {
    this.defaultLoginMethod = 'phone';
    setTimeout(() => {
      //@ts-ignore
      this.loginPhoneRef.first.phoneStatusControl?.phoneInputRef.nativeElement.focus();
    }, 100);
  }

  modelChanged($event: any) {
    if ($event === 'email') {
      this.loginWithEmail();
    }
    if ($event === 'phone') {
      this.loginWithPhone();
    }
    if ($event === 'google') {
      this.goToGoogleClientAuth();
      setTimeout(() => {
        this.defaultLoginMethod = null;
      }, 100);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../@hop/layout/layout.module';
import { CustomLayoutPublicComponent } from './custom-layout-public.component';
import { SidenavModule } from '../../@hop/layout/sidenav/sidenav.module';
import { ToolbarModule } from '../../@hop/layout/toolbar/toolbar.module';
import { ConfigPanelModule } from '../../@hop/components/config-panel/config-panel.module';
import { SidebarModule } from '../../@hop/components/sidebar/sidebar.module';
import { QuickpanelModule } from '../../@hop/layout/quickpanel/quickpanel.module';
import { IfFeatureFlagDirective } from '../../@hop/components/is-feature/is-feature.directive';
import { PublicFooterModule } from 'src/@hop/layout/public-footer/public-footer.module';

import { MatSidenavModule } from '@angular/material/sidenav';
import { OpenMenuUserModule } from 'src/@hop/components/open-menu-user/open-menu-user.module';
import { MenuUserModule } from 'src/@hop/layout/menu-user/menu-user.module';
import { BasketViewModule } from 'src/@hop/components/basket-view/basket-view.module';

@NgModule({
  declarations: [CustomLayoutPublicComponent],
  imports: [
    CommonModule,
    LayoutModule,
    SidenavModule,
    ToolbarModule,
    PublicFooterModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    IfFeatureFlagDirective,
    OpenMenuUserModule,
    MatSidenavModule,
    MenuUserModule,
    BasketViewModule
  ]
})
export class CustomLayoutPublicModule { }

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../../@hop/services';
import { Store } from '@ngxs/store';
import { UserLogout } from '../../@hop/state';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authenticationService.user;
    if (user) {
      // logged in so return true
      return true;
    } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
      this.store.dispatch(new UserLogout());
      console.log('redirect to login');
      return false;
    }
  }
}

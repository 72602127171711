import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { AppHopState, UserState } from '../../state';
import { Select } from '@ngxs/store';
import { UserModel, UserProfileModel } from '../../models';
import { Observable, combineLatest, debounceTime, distinctUntilChanged, filter, firstValueFrom, map, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, ActivationEnd, ActivationStart, ParamMap, Router, RouterEvent } from '@angular/router';
import { PublicProfileService } from 'src/@hop/services/public-profile.service';
import { AppState } from 'src/app/state/states/app-state.service';

@UntilDestroy()
@Component({
  selector: 'hop-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent implements OnInit, OnDestroy {
  username: string;
  @Select(AppState.selectLanguage) language$: Observable<any>;
  @Select(AppHopState.selectBrand) brand$: Observable<String>;
  languageCode: string;
  showCustomValues: boolean = false;
  userProfile;
  constructor(private router: Router, private route: ActivatedRoute, private publicUserService: PublicProfileService) {
    this.publicUserService.userProfile$.pipe(untilDestroyed(this)).subscribe((userProfile) => {
      this.showCustomValues = !!userProfile;
      this.userProfile = userProfile;
    });
  }

  checkIfShowSocialUrl(socialUrl) {
    if (this.userProfile) {
      if (this.showCustomValues) {
        if (this.userProfile[socialUrl]) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  }

  ngOnInit() {}

  ngOnDestroy(): void {}
}

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject('environment') private environment
  ) { }

  getAll() {
    return this.http.get<UserModel[]>(`${this.environment.apiUrl}/users`);
  }

  getUser() {
    return this.http.get<UserModel>(`${this.environment.apiUrl}/profile`);
  }
  saveUser(user) {
    if (user?.username) user.username = user.username.toLowerCase();
    return this.http.patch<UserModel>(`${this.environment.apiUrl}/profile`, user);
  }


  sendMessageContactForm(username, contactFormDetails) {
    return this.http.post(`${this.environment.apiUrl}/users/${username}/send-message`, contactFormDetails);
  }

  removeTermsAccepted() {
    return this.http.get(`${this.environment.apiUrl}/users/test/remove-terms-accepted`);
  }
  removeTestUser() {
    return this.http.get(`${this.environment.apiUrl}/users/test/remove-test-user`);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { fadeInUp400ms } from '../../../../../../@hop/animations/fade-in-up.animation';
import { Select, Store } from '@ngxs/store';
import { UserLogin } from 'src/@hop/state/actions/user.action';
import { UserState } from 'src/@hop/state/states/user-state.service';
import { Observable } from 'rxjs';
import { UserModel } from '../../../../../../@hop/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as cookie from 'js-cookie';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormErrorsModule } from '../../../../../../@hop/components/mat-form-errors/mat-form-errors.module';
import { ResponseErrorModel } from '../../../../../../@hop/models/responseErrorModel';
import { AuthenticationService, FormUtilsService } from '../../../../../../@hop/services';
import { UserPhoneCodeComponent } from 'src/@hop/components/user-phone-code/user-phone-code.component';
import { PhoneInputComponent } from 'hop-calendar';

@UntilDestroy()
@Component({
  selector: 'hop-login-phone-code',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    TranslateModule,
    RouterLink,
    UserPhoneCodeComponent,
    MatFormErrorsModule,
    PhoneInputComponent
  ],
  template: `
    <div (keyup.enter)="send()" [formGroup]="form" class="flex flex-col gap-4">
      <div class="flex flex-col">
        <div class="flex flex-col w-full">
          <!--<p>{{ '_contacts._label.phone' | translate }}</p>-->
          <mat-form-field appearance="fill" class="w-full" floatLabel="always">
            <!-- <mat-label>{{ '_contacts._label.phone' | translate }}</mat-label> -->
            <!-- <input [placeholder]="'_contacts._placeholder.phone' | translate" formControlName='phone' matInput
                         value='' /> -->
            <phone-input
              (click)="clickPhone()"
              inputPlaceholder="{{ '_contacts._label.phone' | translate }}"
              [preferredCountries]="preferredCountries"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              name="phone"
              formControlName="phone"
              #phone
            ></phone-input>
            <mat-error data-testid="error-input-phone">
              <hop-mat-form-error control="phone"></hop-mat-form-error>
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="flex-1">
          <mat-label>{{ '_label.phone-code' | translate }}</mat-label>
          <hop-user-phone-code
            [disabled]="!f.phone.valid"
            [phoneToSend]="f.phone.value"
            formControlName="phoneCode"
            #phoneCodeRef
            [checkIfPhoneExists]="true"
          ></hop-user-phone-code>
          <mat-error>
            <hop-mat-form-error control="phoneCode"></hop-mat-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="passwordIsEnough" class="flex items-center justify-between">
        <mat-checkbox formControlName="rememberMe" class="caption" color="primary">{{ '_account._login.remember-me' | translate }}</mat-checkbox>
      </div>

      <button *ngIf="passwordIsEnough" (click)="send()" color="primary" mat-raised-button type="button" data-testid="button-sign-in">
        {{ '_account._login.sign-in' | translate }}
      </button>
    </div>
  `,
  //styleUrls: ['./login-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUp400ms]
})
export class LoginPhoneCodeComponent implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  @Input() navigateToDashboard: boolean = true;
  @Input() phone: any;
  @Output() phoneClicked = new EventEmitter();
  @ViewChild('phoneCodeRef') public phoneCodeRef: UserPhoneCodeComponent;
  private loading: boolean;
  preferredCountries: string[];

  get passwordIsEnough() {
    return this.form.get('phoneCode').value?.length > 5;
  }

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    private authService: AuthenticationService,
    private formUtilsService: FormUtilsService,
    @Inject('environment') private environment
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((e: NavigationStart) => {
      const navigation = this.router.getCurrentNavigation();
      this.phone = navigation?.extras?.state ? navigation.extras.state['phone'] : this.phone;
    });
  }

  ngOnInit() {
    this.preferredCountries = this.environment.appConfig.preferredPhoneCountryCodes;
    this.form = this.fb.group({
      phone: [this.phone, Validators.required],
      phoneCode: ['', Validators.required],
      rememberMe: [false]
    });
  }

  ngAfterViewInit(): void {}

  send() {
    if (this.form.invalid) {
      return;
    }
    this.authService
      .loginWithPhoneCode(this.f['phone']?.value, this.f['phoneCode']?.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (this.navigateToDashboard) {
            this.router.navigate(['/dashboard']);
          }
        },
        error: (error: { error: ResponseErrorModel }) => {
          this.loading = false;
          if (error.error.errors) {
            this.formUtilsService.setFormErrors(error.error, this.form);
          }
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  get f() {
    return this.form.controls;
  }

  forgotPassword() {
    cookie.set('auth-close', 'true', {
      expires: 0.01
    });
  }

  clickPhone() {
    this.phoneClicked.emit(true);
  }
}

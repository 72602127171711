import { BaseObjectModel } from './base.model';

export class Payment extends BaseObjectModel {
  name: string;

  type: PaymentType;

  number?: string;

  amount: number;

  datePayment?: string;

  appointmentId: number;

  request?: any;

  response?: any;

  status?: PaymentStatus;

  message?: string;

  link?: string;

  currency?: PaymentCurrency;
  constructor(payment) {
    super(payment, true);
  }
}
export class PaymentPaginationModel {
  items: Payment[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPage: number;
  };
}

export enum PaymentType {
  NUMERAR = 'NUMERAR',
  OP = 'OP',
  CARD = 'CARD'
}
export enum PaymentStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  EROARE = 'ERROR'
}

export enum PaymentCurrency {
  RON = 'RON',
  EUR = 'EUR'
}

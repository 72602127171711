import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { ReviewsService } from 'src/@hop/services/review.service';
import { TimeUtilsService } from 'src/@hop/services/timeUtils.service';
import { UserState } from 'src/@hop/state';
import { DeleteDialogComponent } from '../../availability-component/delete-dialog/delete-dialog.component';
import { InViewService } from 'src/@hop/services/in-view.service';
import { WriteReviewComponentComponent } from '../write-review-component/write-review-component.component';
import { EditReviewComponentComponent } from '../edit-review-component/edit-review-component.component';
import { ActivatedRoute } from '@angular/router';
import { ReviewModel } from 'src/@hop/models/review.model';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'hop-full-review-component',
  templateUrl: './full-review-component.component.html',
  styleUrls: ['./full-review-component.component.scss']
})
export class FullReviewComponentComponent implements OnInit {
  @Input() review: ReviewModel;
  @Input() showProduct;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  user: UserModel;
  @Output() reviewDeleted = new EventEmitter();
  username: string;

  constructor(
    public timeUtilsService: TimeUtilsService,
    private reviewsService: ReviewsService,
    private toastr: ToastrService,
    public translateService: TranslateService,

    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.username = this.route?.snapshot?.paramMap?.get('username') || this.username;
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = { ...user };
    });
  }
  ngOnInit(): void {}

  openEditDialogReview() {
    this.dialog
      .open(EditReviewComponentComponent, {
        data: {
          body: this.review?.body,
          rating: this.review?.rating
        },
        panelClass: 'dialog-open-availability'
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value?.reviewValue) {
          this.reviewsService.editReview({ ...this.review, ...value.reviewValue }).subscribe({
            next: (result) => {
              this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.review_edited'), {
                timeOut: 3000,
                progressBar: true
              });
              this.review = { ...this.review, ...result };
            },
            error: (error) => {
              if (error.error) {
              }
            }
          });
        }
      });
  }

  deleteReview(review) {
    this.dialog
      .open(DeleteDialogComponent, {
        data: {
          name: review?.body,
          content: 'This action cannot be undone. Are you sure you want to cancel this product?'
        },
        panelClass: 'dialog-open-availability'
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value?.confirmDelete === true) {
          this.reviewsService.removeReview(this.review?.uuid).subscribe({
            next: (result) => {
              this.toastr.success(this.translateService.instant('_general.changes_saved'), this.translateService.instant('_general.review_deleted'), {
                timeOut: 3000,
                progressBar: true
              });
              this.reviewDeleted.next(true);
              // this.router.navigate([`/products`]);
            },
            error: (error) => {
              if (error.error) {
              }
            }
          });
        }
      });
  }
}

import { Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UserState } from '../../state';
import { Select } from '@ngxs/store';
import { UserModel } from '../../models';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InViewService } from '../../services/in-view.service';

@UntilDestroy()
@Component({
  selector: 'hop-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  @Select(UserState.selectUser) user$: Observable<UserModel>;
  @Input() customTemplate: TemplateRef<any>;
  constructor(private element: ElementRef, private inViewService: InViewService) {}

  ngOnInit() {
    // this.inViewService
    //   .elementInSight(this.element, { rootMargin: '0px 0px 500px 0px' })
    //   .pipe(untilDestroyed(this))
    //   .subscribe((visible) => {
    //     console.log(visible);
    //   });
  }

  ngOnDestroy(): void {}
}

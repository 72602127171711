import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastrInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request?.headers.has('skiperrors') &&
      (request.method === 'POST' || request.method === 'PATCH' || request.method === 'DELETE' || request.method === 'PUT')
    ) {
      return next.handle(request).pipe(
        tap((evt) => {
          if (evt instanceof HttpResponse) {
            if (evt.body && evt.body.success && evt.body.warning) {
              this.toastr.warning(evt.body.message, evt.body.title, { timeOut: 10000, progressBar: true });
            } else if (evt.body && evt.body.success && evt.body.message) {
              this.toastr.success(evt.body.message, evt.body.title, { timeOut: 10000, progressBar: true });
            } else if (evt.body && !evt.body.success && evt.body.message) {
              this.toastr.warning(evt.body.message, evt.body.title, { timeOut: 10000, progressBar: true });
            }
          }
        }),
        catchError((err: any) => {
          let errorText = 'Unknown error!';
          if (err instanceof HttpErrorResponse) {
            if (err.name === 'HttpErrorResponse') {
              errorText = this.translateService.instant('_error.HttpErrorResponse');
            }
            errorText = err.error?.message || errorText;
          }
          if (err?.error?.statusCode === 400 && errorText === 'Bad Request') {
            errorText = this.translateService.instant('_error.formApiErrors');
          }
          if (err?.error?.statusCode === 429) {
            const retryAfterShort = err?.headers.get('Retry-After-Short');
            const retryAfterMedium = err?.headers.get('Retry-After-Medium');
            const retryAfterLong = err?.headers.get('Retry-After-Long');
            errorText = this.translateService.instant('_error.too-many-requests');
            if (retryAfterShort || retryAfterMedium || retryAfterLong) {
              errorText = this.translateService.instant('_error.too-many-requests-seconds', {
                seconds: retryAfterShort || retryAfterMedium || retryAfterLong
              });
            }
            //this.toastr.error(errorMessage, '', { timeOut: 15000 });
            //set header skipErrors: 'true'
          }
          this.toastr.warning(errorText, err?.error?.title, { timeOut: 15000, progressBar: true });

          // return of(err);
          return throwError(err);
        })
      );
    }
    return next.handle(request);
  }
}

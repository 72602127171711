import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IfFeatureFlagDirective } from '../is-feature/is-feature.directive';
import { OpenMenuUserComponent } from './open-menu-user.component';

@NgModule({
  declarations: [OpenMenuUserComponent],
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatCheckboxModule, IfFeatureFlagDirective],
  exports: [OpenMenuUserComponent]
})
export class OpenMenuUserModule { }

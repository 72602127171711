import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { PublicProfileModel } from '../models/public-profile.model';
import { PaginationFilterOptions, PaginationFilterOrdersOptions } from '../models/pagination.model';
import { OrderModel } from '../models/order.model';
import { ProviderModel } from '../models/provider.model';
import { PublicProfileService } from './public-profile.service';
import { SubscriptionWebsocketResponse, WebsocketService } from './websocket.service';
import { debounceTime } from 'rxjs/operators';
import { OrderProduct } from '../models/order-product.model';
import { Select } from '@ngxs/store';
import { UserState } from '../state';
import { UserModel } from '../models';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  endpoint = `${this.environment.apiUrl}/orders`;
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  cart$: BehaviorSubject<OrderModel> = new BehaviorSubject<OrderModel>(null);
  cartWebsocketSubscription: Subscription;
  fromWebsocketCart$: BehaviorSubject<SubscriptionWebsocketResponse> = new BehaviorSubject<SubscriptionWebsocketResponse>(null);

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    private publicUserService: PublicProfileService,
    private websocketService: WebsocketService
  ) {
    // allow the cart to be refreshed on route change
    combineLatest([this.publicUserService.username$ /*.pipe(distinctUntilChanged())*/, this.fromWebsocketCart$, this.user$])
      .pipe(debounceTime(500))
      .subscribe(([username, fromWebsocketCart, user]) => {
        if (username && user) {
          this.getCart(username).subscribe((cart) => {
            this.cart$.next(cart);
            this.websocketService.addUuidSubscription(cart.uuid, this.fromWebsocketCart$, this);
          });
        }
      });
  }

  getCart(username): Observable<any> {
    return this.http.get<any>(this.endpoint + '/cart/' + username).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  incrementOrderProductQuantity(uuid): Observable<any> {
    return this.http.post<any>(this.endpoint + `/increment-order-product/${uuid}`, '').pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  decrementOrderProductQuantity(uuid): Observable<any> {
    return this.http.post<any>(this.endpoint + `/decrement-order-product/${uuid}`, '').pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getMyOffset(
    options: PaginationFilterOptions = {
      filter: {},
      sortName: 'dateCreated',
      sortOrder: 'asc',
      page: 1,
      limit: 10,
      isClient: false
    }
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(options.filter))
          .set('sortName', options.sortName)
          .set('sortOrder', options.sortOrder)
          .set('page', options.page)
          .set('limit', options.limit)
          .set('isClient', options?.isClient)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getOrderByCode(uuid: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/code/${uuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  cancelOrder(uuid: string, reason?: boolean): Observable<any> {
    return this.http.delete<any>(this.endpoint + `/cancel`, { body: { uuid: uuid, reason: reason } }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  cancelOrderAsClient(uuid: string, reason?: boolean): Observable<any> {
    return this.http.post<any>(this.endpoint + `/cancel-client`, { uuid: uuid, reason: reason }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  updateOrder(uuid, order): Observable<any> {
    return this.http.put<any>(this.endpoint + `/update/${uuid}`, order).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getPaymentProviders(orderUuid: string): Observable<ProviderModel[]> {
    return this.http.get<any>(this.endpoint + `/payment-providers/${orderUuid}`).pipe(
      map((providers) => {
        return providers.map((provider) => new ProviderModel(provider));
      })
    );
  }

  modifyOrderProductQuantity(orderProduct: OrderProduct) {
    return this.http.post<any>(this.endpoint + `/modify-order-product-quantity`, { uuid: orderProduct.uuid, quantity: orderProduct.quantity }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getMyOrdersWithCursor(
    limit = 10,
    isClient = false,
    filter: any = {},
    sortName = 'dateStart',
    sortOrder = 'asc',
    page = 1,
    cursorUuid = '',
    showAll = false,
    previous = false
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/cursor', {
        params: new HttpParams()
          .set('filter', JSON.stringify(filter))
          .set('sortName', sortName)
          .set('sortOrder', sortOrder)
          .set('page', page)
          .set('limit', limit)
          .set('cursorUuid', cursorUuid.toString())
          .set('show-all', showAll)
          .set('previous', previous)
          .set('isClient', isClient)
        // context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getMyOrdersWithPagination(
    options: PaginationFilterOrdersOptions = {
      filter: {},
      sortName: '',
      sortOrder: 'desc',
      page: 1,
      limit: 10,
      isClient: false
    }
  ): Observable<any> {
    return this.http
      .get<any>(this.endpoint + '/my/offset', {
        params: new HttpParams()
          .set('filter', JSON.stringify(options.filter))
          .set('sortName', options.sortName)
          .set('sortOrder', options.sortOrder)
          .set('page', options.page)
          .set('limit', options.limit)
          .set('isClient', options.isClient)
      })
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  editOrder(orderInformation): Observable<any> {
    return this.http.patch<any>(this.endpoint, orderInformation).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getOrderByUuid(uuid: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/uuid/${uuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  setOrderToPaymentInProgress(uuid: string): Observable<any> {
    return this.http.post<any>(this.endpoint + `/payment-in-progress/${uuid}`, {}).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  clientSendOrderNoPaymentProviders(uuid: string) {
    return this.http.post<any>(this.endpoint + `/client-send-order-no-payment-providers/${uuid}`, {}).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  confirmOrder(uuid: string, reason) {
    return this.http.patch<any>(this.endpoint + `/complete`, { uuid: uuid, reason: reason }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getStripeTestPaymentLink(uuid: string) {
    return this.http.get<any>(this.endpoint + `/payment-link-stripe-test/${uuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  sendWithBankPayment(orderUuid: string, data: any) {
    return this.http.put<any>(this.endpoint + `/send-with-bank-payment/${orderUuid}`, data).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  sendWithCashPayment(orderUuid: string, data: any) {
    return this.http.put<any>(this.endpoint + `/send-with-cash-payment/${orderUuid}`, data).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  //check-existing-order-product
  checkExistingOrderProduct(productUuid: string): Observable<any> {
    return this.http.get<any>(this.endpoint + `/get-existing-order-product/${productUuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getStripeLivePaymentLink(uuid: string) {
    return this.http.get<any>(this.endpoint + `/payment-link-stripe-live/${uuid}`).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  manuallyCheckOrderPayment(uuid: string) {
    return this.http.get<any>(this.endpoint + `/manually-check-payment/${uuid}`, {}).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasketViewComponent } from './basket-view.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { OrderPaymentButtonComponent } from '../order-payment-button/order-payment-button.component';
import { CheckoutButtonComponent } from '../order-payment-button/order-checkout-button.component';
import { ProductOrderCardComponent } from '../product-order-card/product-order-card.component';

@NgModule({
  declarations: [BasketViewComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    ProductOrderCardComponent,
    TranslateModule,
    OrderPaymentButtonComponent,
    CheckoutButtonComponent
  ],
  exports: [BasketViewComponent]
})
export class BasketViewModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonThemeDarkComponent } from './button-theme-dark.component';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ButtonThemeDarkComponent],
  exports: [ButtonThemeDarkComponent],
  imports: [CommonModule, MatMenuModule, MatIconModule, MatButtonModule]
})
export class ButtonThemeDarkModule {}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError } from 'rxjs';
import { ImageFormatEnum } from '../models/file.model';

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  endpoint = `${this.environment.apiUrl}/files`;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment
  ) {}

  uploadPublicImage(file, options: { width?: number; extension?: ImageFormatEnum } = {}): Observable<any> {
    let params = new HttpParams();
    if (options.width) {
      params = params.set('width', options.width.toString());
    }
    if (options.extension) {
      params = params.set('extension', options.extension);
    }

    return this.http.post<any>(this.endpoint + '/upload-public-image', file, { params }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  uploadPublicFile(file): Observable<any> {
    return this.http.post<any>(this.endpoint + '/upload-public-file', file).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  uploadPublicDocument(file): Observable<any> {
    return this.http.post<any>(this.endpoint + '/upload-public-document', file).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  uploadPublicUrl(url): Observable<any> {
    return this.http.post<any>(this.endpoint + '/upload-public-url', { url: url }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getUnsplashPhotos(query = '', orientation = 'landscape', count = 20) {
    return this.http
      .get<any>(this.endpoint + '/unsplash/get-random-images', {
        params: new HttpParams().set('orientation', orientation).set('count', count).set('query', query)
      })

      .pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
}

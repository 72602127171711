import { Calendar } from './calendar';
import { LanguageModel } from './language.model';
import { FileModel } from './file.model';
import { OfferAppointmentModel } from './appointment.model';

export class UserModel {
  uuid: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  needsPassword: boolean;
  isProfessional: boolean;
  imageFile: {
    name: string;
    url: string;
    uuid: string;
    isPublic: boolean;
  };
  lang?: LanguageModel;
  langCode: string;
  availabilityDefaultCalendar: Calendar;
  writeCalendar: Calendar;
  manyAdditionalLangs: LanguageModel[];
  country: any;
  _identifier_hash: any;
  dateFormat: 'DD_MM_YYYY' | 'MM_DD_YYYY';
  isPhoneVerified: boolean;
  phone: string;
}

export interface UserProfileModel {
  _productsCount?: number;
  _storiesCount?: number;
  welcomeMessage: string;
  imageUrl: string;
  videoUrl: string;
  uuid: string;
  bio: string;
  facebookUrl: string;
  instagramUrl: string;
  linkedInUrl: string;
  otherMessage: string;
  twitterUrl: string;
  websiteUrl: string;
  workCompany: string;
  workPosition: string;
  city: string;
  langCode: string;
  imageFile: FileModel;
  lang: LanguageModel;
  offerAppointments: OfferAppointmentModel[];
  user: UserModel;
}

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LayoutService } from '../../@hop/services';
import { debounceTime, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@hop/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@hop/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@hop/components/sidebar/sidebar.component';
import { UserState } from '../../@hop/state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FeatureEnum, UserModel } from '../../@hop/models';
import { MatSidenav } from '@angular/material/sidenav';

@UntilDestroy()
@Component({
  selector: 'hop-custom-public-layout',
  templateUrl: './custom-layout-public.component.html',
  styleUrls: ['./custom-layout-public.component.scss']
})
export class CustomLayoutPublicComponent implements OnInit {
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map((config) => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;
  isMedium$ = this.layoutService.gtMd$;
  @Input() menuUserRef: TemplateRef<any>;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, (data) => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChild('menuUser', { static: true }) menuUser: MatSidenav;
  @ViewChild('basket', { static: true }) basket: SidebarComponent;

  constructor(
    public layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {}

  ngOnInit() {
    this.isDesktop$
      .pipe(
        filter((matches) => !matches),
        untilDestroyed(this)
      )
      .subscribe(() => this.layoutService.expandSidenav());

    this.layoutService.configpanelOpen$.pipe(untilDestroyed(this)).subscribe((open) => (open ? this.configpanel.open() : this.configpanel.close()));
    this.layoutService.menuOpen$.pipe(untilDestroyed(this)).subscribe((open) => {
      open ? this.menuUser.open() : this.menuUser?.close();
    });
    this.layoutService.basketOpen$
      .pipe(untilDestroyed(this), debounceTime(100))
      .subscribe((open) => (open ? this.basket.open() : this.basket.close()));

    // this.layoutService.menuOpen$.pipe(untilDestroyed(this)).subscribe((open) => (open ? this.menuUser.open() : this.menuUser?.close()));
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(this.isDesktop$),
        filter(([event, matches]) => !matches),
        untilDestroyed(this)
      )
      .subscribe(() => this.sidenav?.close());
  }

  cl() {
    console.log('123');
  }

  protected readonly FeatureEnum = FeatureEnum;
}

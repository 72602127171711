import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import * as cookie from 'js-cookie';
@UntilDestroy()
@Component({
  selector: 'hop-cookie-accept',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {
  acceptedCookiePolicy = false;
  constructor() {}

  ngOnInit() {
    this.acceptedCookiePolicy = cookie.get('cookieconsent_status') === 'dismiss';
  }
  acceptCookie() {
    this.acceptedCookiePolicy = true;
    cookie.set('cookieconsent_status', 'dismiss');
  }
}

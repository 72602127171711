import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppPagesService } from '../../@hop/services/app-pages.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SlugGuard  {
  constructor(private appPagesService: AppPagesService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pageSlug = route.paramMap.get('pageSlug');
    // Call the backend service here, and check if the page is exist
    return firstValueFrom(this.appPagesService.getAppPage(pageSlug))
      .then((data) => {
        return true;
      })
      .catch(() => {
        this.router.navigate(['/404']);
        return false;
      });
    /*if (!page) {
      
      await this.router.navigate(['/404']);
    }
    return page;*/
  }
}

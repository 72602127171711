import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom, map, Subject } from 'rxjs';
import { OrderProduct } from 'src/@hop/models/order-product.model';
import { OrdersService } from 'src/@hop/services/orders.service';
import { catchError, debounceTime } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LabelsOfferProductComponent } from '../appointment/labels-offer-product.component';
import { LinkComponent } from '../links/open-link.component';
import { OrderAppointmentsDatesComponent } from '../order-appointments-dates/order-appointments-dates.component';
import { AppRouterLinkDirective } from '../router-link/router-link.directive';

@UntilDestroy()
@Component({
  selector: 'hop-product-order-card',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    OrderAppointmentsDatesComponent,
    TranslateModule,
    LabelsOfferProductComponent,
    LinkComponent,
    RouterLink,
    AppRouterLinkDirective
  ],
  template: `
    <div
      *ngIf="orderProduct"
      class="card-product-view bg-foreground border border-gray-light-500 flex flex-col rounded-lg hover:shadow-lg space-y-2 overflow-hidden"
    >
      <div class="flex justify-between">
        <div class="flex">
          <img
            [hopRouterLink]="orderProduct.product?._uri"
            [src]="orderProduct?._product?.imageFile?.url || orderProduct?.product?.imageFile?.url"
            alt=""
            class="top-0 left-0 w-20 h-20 object-cover"
          />
          <h4 [hopRouterLink]="orderProduct.product?._uri" class="m-2 self-start line-clamp-3">
            <hop-link [object]="orderProduct.product"></hop-link> {{ orderProduct?._product?.name }}
          </h4>
        </div>
        <!--<p class="text-base font-semibold p-4">X{{orderProduct?.quantity}}</p>-->
      </div>
      <div class="flex flex-col space-y-6 w-full p-3 pb-5">
        <div class="flex justify-between">
          <div class="flex flex-col space-y-2">
            <!-- increment product input -->
            <div class="flex space-x-2">
              <p>{{ '_orders.quantity' | translate }}:</p>
              <div class="flex items-center space-x-2" *ngIf="!readOnly">
                <button
                  data-testid="card-order-product-decrement"
                  (click)="decrementOrderProductQuantity(orderProduct?.uuid)"
                  class="border p-1 rounded-full w-7"
                >
                  -
                </button>
                <p data-testid="card-order-product-quantity">{{ orderProduct?.quantity }}</p>
                <button
                  data-testid="card-order-product-increment"
                  (click)="incrementOrderProductQuantity(orderProduct?.uuid)"
                  class="border p-1 rounded-full w-7"
                >
                  +
                </button>
              </div>
              <div *ngIf="readOnly">
                <p data-testid="card-order-product-quantity">{{ orderProduct?.quantity }}</p>
              </div>
            </div>
            <span class="text-gray text-small" *ngIf="orderProduct?.quantity > 1"
              >X {{ orderProduct?.unitAmount }} {{ orderProduct?.currencyCode }}</span
            >
          </div>
          <div>
            <p data-testid="card-order-product-subtotal" class="line-through" *ngIf="orderProduct?.discount > 0">
              {{ '_orders.subtotal' | translate }}: {{ orderProduct?.subtotal }} {{ orderProduct?.currencyCode }}
            </p>
            <p data-testid="card-order-product-total" *ngIf="orderProduct?.discount > 0" class="text-green-500">
              {{ '_orders.total' | translate }}:
              <span data-testid="card-order-product-total">{{ orderProduct?.total }}</span>
              {{ orderProduct?.currencyCode }}
            </p>
            <p *ngIf="orderProduct?.discount <= 0">
              <span data-testid="card-order-product-total">{{ orderProduct?.total }}</span> {{ orderProduct?.currencyCode }}
            </p>
            <p data-testid="card-order-product-tax" *ngIf="orderProduct?.taxValue > 0">
              {{ '_orders.taxValue' | translate }}: {{ orderProduct?.taxValue }} {{ orderProduct?.currencyCode }}
            </p>
          </div>
        </div>

        <hop-order-appointments-dates
          [isCancelled]="isCancelled"
          [readonly]="readOnly"
          (appointmentDeleted)="cartChanged.emit(true)"
          [orderProduct]="orderProduct"
        ></hop-order-appointments-dates>
      </div>
    </div>
  `
})
export class ProductOrderCardComponent implements OnInit {
  cart: any;
  @Input() orderProduct: OrderProduct;
  @Input() isCancelled: boolean = false;
  @Input() readOnly: boolean = false;
  @Output() cartChanged = new EventEmitter<boolean>();
  constructor(private ordersService: OrdersService) {}

  modifyQuantity$: Subject<OrderProduct> = new Subject();

  ngOnInit() {
    this.modifyQuantity$.pipe(untilDestroyed(this), debounceTime(500)).subscribe((quantity) => {
      this.ordersService
        .modifyOrderProductQuantity(this.orderProduct)
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            this.cartChanged.emit(true);
            return err;
          })
        )
        .subscribe((data) => {
          this.cartChanged.emit(true);
        });
    });
  }

  incrementOrderProductQuantity(uuid) {
    this.orderProduct.quantity += 1;
    this.modifyQuantity$.next(this.orderProduct);
  }

  decrementOrderProductQuantity(uuid) {
    if (this.orderProduct.quantity >= 1) {
      this.orderProduct.quantity -= 1;
      this.modifyQuantity$.next(this.orderProduct);
    }
  }
}

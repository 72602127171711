<div [formGroup]="form" class="flex flex-col space-y-4">
  <div class="flex flex-col space-y-20">
    <h1 class="text-xl justify-center">{{"_authentication.enter-the-code-you-received-at-email" | translate }}
      {{(user$|async).email}}</h1>
  </div>
  <mat-form-field>
    <input formControlName="verifyEmail" matInput [placeholder]="'_placeholder.verify-email-example'|translate" />
    <!-- <mat-error *ngIf="form.get('verifyEmail').hasError('required')">Required</mat-error> -->
  </mat-form-field>
  <div class="flex flex-col space-y-4 md:flex-row md:justify-between md:space-y-0">
    <a (click)="resendCode()" class="w-full md:w-40" color="primary" mat-stroked-button>{{"_authentication.resend-code"
      | translate }}</a>

    <a (click)="submit()" class="w-full md:w-40" color="primary" mat-raised-button
      [disabled]="!form.dirty || !form.valid">{{"_general.save" | translate}}</a>
  </div>
</div>
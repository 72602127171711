export class BaseObjectModel {
  id: number;
  uuid: string;
  _dateCreated?: Date;
  _dateUpdated?: Date;
  _dateDeleted?: Date;
  _uri?: string;
  constructor(data: any, autoFill = false) {
    this.id = data?.id ? data.id : 0;
    this.uuid = data?.uuid ? data.uuid : null;
    this._dateCreated = data?._dateCreated ? new Date(data._dateCreated) : null;
    this._dateUpdated = data?._dateUpdated ? new Date(data._dateUpdated) : null;
    this._dateDeleted = data?._dateDeleted ? new Date(data._dateDeleted) : null;
    if (autoFill) {
      this.autoFillProperties(data);
    }
  }

  autoFillProperties(data: any, ignoreDefined = true) {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (!(ignoreDefined && typeof this[key] !== 'undefined')) {
          this[key] = value;
        }
      }
    }
  }
}

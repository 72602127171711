import { Language } from 'src/@hop/models/language-model';
import { ColorSchemeName } from '../../config/colorSchemeName';

export class ColorSchemeSet {
  static readonly type = '[App] ColorSchemeUpdate';
  constructor(public colorScheme: ColorSchemeName) {}
}

export class LanguageSet {
  static readonly type = '[App] LanguageUpdate';
  constructor(public language: Language) {}
}
export class BrandSet {
  static readonly type = '[App] BrandUpdate';
  constructor(public brand: string) {}
}

export class LanguagesSet {
  static readonly type = '[App] LanguagesUpdate';
  constructor(public availableLanguages: Language[]) {}
}

export class ApiNameSet {
  static readonly type = '[App] ApiName Set';
  constructor(public apiName: string) {}
}

export class AppVersionSet {
  static readonly type = '[App] ApiVersion Set';
  constructor(public appVersion: string) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageMenuComponent } from './language-menu.component';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { IconFlagComponent } from '../icon-flag/icon-flag.component';

@NgModule({
  declarations: [LanguageMenuComponent],
  exports: [LanguageMenuComponent],
  imports: [CommonModule, MatMenuModule, MatIconModule, MatButtonModule, MatInputModule, IconFlagComponent]
})
export class LanguageMenuModule { }

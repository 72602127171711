import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  constructor(
    private readonly updates: SwUpdate,
    private snackbar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.updates.versionUpdates.subscribe((event) => {
      //console.log('versionUpdates', event);
      if (event.type === 'VERSION_READY') {
        console.log('version ready, show alert');
        this.showAppUpdateAlert();
      }
      if (event.type === 'VERSION_INSTALLATION_FAILED') {
        new Error('version installed failed ' + JSON.stringify(event));
      }
    });
    setInterval(
      () => {
        if (this.updates.isEnabled) {
          this.updates.checkForUpdate().then();
        }
      },
      3 * 60 * 1000
    );
  }
  showAppUpdateAlert() {
    const header = 'App Update available';
    const message = this.translateService.instant('_general.update-available-click-ok-to-update');
    const action = this.doAppUpdate;
    const caller = this;
    // Use MatDialog or ionicframework's AlertController or similar
    this.snackbar
      .open(message, 'Ok', {
        //duration: 3000000,
        panelClass: 'snackbar-primary'
      })
      .onAction()
      .subscribe(() => {
        this.doAppUpdate();
      });
  }
  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}

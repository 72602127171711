import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormErrorsComponent } from './mat-form-errors.component';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [MatFormErrorsComponent],
  imports: [CommonModule, MatInputModule],
  exports: [MatFormErrorsComponent]
})
export class MatFormErrorsModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hop-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {}
  dialogTitle: string;

  ngOnInit(): void {
    if (this.data?.name) {
      this.dialogTitle = this.translateService.instant('_general.delete') + ` ${this.data.name}?`;
    } else {
      this.dialogTitle = this.translateService.instant('_general.cannot-be-deleted');
    }
  }
  onClose(): void {
    this.dialogRef.close();
  }

  onConfirmDelete() {
    this.dialogRef.close({ confirmDelete: true });
  }
}

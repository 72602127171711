<div class="px-1 menu-button">
  <button *ngIf='!showName' [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
    <hop-icon-flag class="w-6" [code]="(language$|async).code"></hop-icon-flag>
  </button>
  <button *ngIf='showName' [matMenuTriggerFor]="languageMenu" mat-flat-button type="button">
    <hop-icon-flag class="pr-2" [code]="(language$|async).code"></hop-icon-flag> <span>{{(language$|async).name}}</span>
  </button>
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
  <div *ngIf='!manyAdditionalLangs?.length'>
    <button mat-menu-item class="flex items-center" (click)="selectLang(language)"
      *ngFor='let language of selectAvailableLanguages' [hidden]='(language$|async).code === language.code'>
      <hop-icon-flag class="pr-2" [code]="language?.code"></hop-icon-flag>
      <span>{{language.name}}</span>
    </button>
  </div>
  <div *ngIf='manyAdditionalLangs?.length'>
    <button mat-menu-item class="flex items-center" (click)="selectLang(language)"
      *ngFor='let language of manyAdditionalLangs' [hidden]='(language$|async).code === language.code'>
      <hop-icon-flag class="pr-2" [code]="language?.code"></hop-icon-flag>
      <span>{{language.name}}</span>
    </button>
  </div>
</mat-menu>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullReviewComponentComponent } from './full-review-component.component';
import { MatButtonModule } from '@angular/material/button';
import { StarsRatingModule, StarsRatingViewModule } from 'hop-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WriteReviewComponentModule } from '../write-review-component/write-review-component.module';
import { EditReviewComponentModule } from '../edit-review-component/edit-review-component.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ViewMoreModule } from 'src/@hop/components/directives/view more/view-more-directive.module';

@NgModule({
  declarations: [FullReviewComponentComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    StarsRatingViewModule,
    WriteReviewComponentModule,
    FormsModule,
    EditReviewComponentModule,
    RouterModule,
    TranslateModule,
    ViewMoreModule
  ],
  exports: [FullReviewComponentComponent]
})
export class FullReviewComponentModule {}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { Utils } from './services';
import { NgxsModule } from '@ngxs/store';
import { FeaturesState } from './state';
import { UserState } from './state';
import { Environment } from './models';
import { CachingService } from './services/caching.service';
import { InViewService } from './services/in-view.service';
import { FiltersState } from './state/states/filters-state.service';

@NgModule({
  imports: [CommonModule, LayoutModule, NgxsModule.forFeature([FeaturesState, UserState, FiltersState])],
  exports: [LayoutModule],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    Utils
  ]
})
export class HopModule {
  static forRoot(environment: Environment): ModuleWithProviders<any> {
    return {
      ngModule: HopModule,
      providers: [{ provide: 'environment', useValue: environment }, CachingService, InViewService]
    };
  }
}

import { Component, Input } from '@angular/core';


@Component({
  standalone: true,
  imports: [],
  selector: 'hop-icon-flag',
  template: `
      <img class="w-5  inline-block" [src]="'/assets/img/icons/flags/'+ code + '.svg'" alt="">
  `
})
export class IconFlagComponent {
  @Input() code: string;

}

<div class="footer py-12 p-6" fxLayout="row">
  <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center" hopContainer class="container">
    <div class="flex flex-col space-y-4 items-center justify-between w-full md:flex-row md:space-y-0">

      <div class='flex flex-row items-center cursor-pointer'
        [routerLink]="showCustomValues ? ['/@' + userProfile?.user?.username] : ['/']">
        <mat-icon *ngIf='!userProfile?.user?.imageFile?.url' svgIcon="logo:logo"
          class="cursor-pointer icon-5xl flex"></mat-icon>
        <img *ngIf='userProfile?.user?.imageFile?.url'
          class='w-16 h-16 max-w-16 max-h-16 object-cover rounded-full border-2 shadow'
          [src]='userProfile.user.imageFile.url' alt=''>
        <h1 *ngIf="userProfile?.user?.firstName && userProfile?.user?.lastName"
          class="title ltr:pl-4 rtl:pr-4 m-0 select-none ">{{userProfile?.user?.firstName+' '+
          userProfile?.user?.lastName}}</h1>
      </div>

      <div class="flex space-x-0 sm:space-x-4">
        <a *ngIf="checkIfShowSocialUrl('facebookUrl')" target="_blank"
          [href]="showCustomValues ? userProfile?.facebookUrl : '#'" mat-icon-button type="button">
          <mat-icon svgIcon="social:facebook"></mat-icon>
        </a>

        <a *ngIf="checkIfShowSocialUrl('instagramUrl')" target="_blank"
          [href]="showCustomValues ? userProfile?.instagramUrl : '#'" mat-icon-button type="button">
          <mat-icon svgIcon="social:instagram"></mat-icon>
        </a>

        <a *ngIf="checkIfShowSocialUrl('twitterUrl')" target="_blank"
          [href]="showCustomValues ? userProfile?.twitterUrl : '#'" mat-icon-button type="button">
          <mat-icon svgIcon="social:twitter"></mat-icon>
        </a>
        <a *ngIf="checkIfShowSocialUrl('linkedInUrl')" target="_blank"
          [href]="showCustomValues ? userProfile?.linkedInUrl : '#'" mat-icon-button type="button">
          <mat-icon svgIcon="social:linkedin"></mat-icon>
        </a>

        <hop-language-menu></hop-language-menu>
        <hop-button-theme-dark></hop-button-theme-dark>
      </div>
    </div>

    <!-- <div class="ltr:ml-4 rtl:mr-4" fxHide fxShow.gt-sm>TopSejur Admin</div> -->
  </div>
</div>
<div class="card-generic space-y-4">
  <div class="w-full flex flex-col space-y-5 md:flex-row md:space-y-0 md:justify-between">
    <div class="self-start md:mr-10 flex flex-row space-x-3 md:space-x-0 md:flex-col md:space-y-2 w-16">
      <img class="rounded-full h-16 w-16 justify-center m-auto object-contain"
        [src]="review?.user?.imageFile?.url || '../../../../../assets/img/profile-image.png'" />
      <p class="username text-sm self-center">
        {{ review?.user?.username }}
      </p>
    </div>

    <div class="w-full flex flex-col space-y-3">
      <div class="flex flex-col-reverse sm:flex-row sm:justify-between">
        <app-stars-rating-view [selectedValueView]="review?.rating"></app-stars-rating-view>
        <p class="">{{ timeUtilsService.formatDate(review?._dateCreated, translateService) }}</p>
      </div>
      <div readMore="300px">
        <p style="overflow-wrap: anywhere" class="whitespace-pre-line">
          {{ review?.body }}
          <span class="text-gray text-sm" *ngIf="review?.isEdited">(edited)</span>
        </p>
      </div>
      <p *ngIf="showProduct">
        {{ '_general.service' | translate }}:
        <a class="underline text-primary" [routerLink]="review?.product._uri">{{
          review.product.name }}</a>
      </p>

      <div *ngIf="review?.user?.username === user?.username" class="flex-space-x-3 self-end">
        <button (click)="openEditDialogReview()" type="button" mat-button color="primary">{{ '_general.edit' | translate
          }}</button>
        <button (click)="deleteReview(review)" type="button" mat-button color="primary">{{ '_general.delete' | translate
          }}</button>
      </div>
    </div>
  </div>
</div>
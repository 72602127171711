import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfigModel } from '../models/app-config.model';
import * as cookie from 'js-cookie';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  endpoint = `${this.environment.apiUrl}/config`;

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment
  ) {}

  getConfig(): Observable<AppConfigModel> {
    return this.http.get<any>(this.endpoint + '/').pipe(
      map((data) => {
        return data;
      })
    );
  }

  setConfig(): Observable<any> {
    return this.getConfig().pipe(
      tap((config) => {
        /*if (config.ipLangCode && !cookie.get('lang')) {
          cookie.set('lang', config.ipLangCode);
        }*/
        this.environment.appConfig = { ...config };
      })
    );
  }
}

import { Params } from '@angular/router';

export class FiltersGet {
  static readonly type = '[Filters] Get';
}

export class FilterAdd {
  static readonly type = '[Filters] Update';
  constructor(public path: string, public data: any) { }
}

export class FilterRemove {
  static readonly type = '[Filters] Remove';
  constructor(public name: string) { }
}



import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppHopState, AppHopStateModel } from '../../../@hop/state';
import { ColorSchemeName } from '../../../@hop/config/colorSchemeName';
import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { UserService } from '../../../@hop/services';
import { ConfigService } from '../../../@hop/config/config.service';
import { Observable } from 'rxjs';
// import { TestSet } from '../actions/app.action';

export class AppStateModel extends AppHopStateModel {
  //testData: any;
}

// @State<AppStateModel>({
//   name: 'appState',
//   defaults: {
//     colorScheme: ColorSchemeName.light
//     //testData: 'test'
//   }
// })
@UntilDestroy()
@Injectable()
export class AppState extends AppHopState {
  /*@Action(TestSet)
  testSet(ctx: StateContext<AppStateModel>, testData: any) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      testData
    });
  }*/
}

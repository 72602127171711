import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppHopState, ColorSchemeSet } from '../../state';
import { Observable } from 'rxjs';
import { ColorSchemeName } from '../../config/colorSchemeName';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'hop-button-theme-dark',
  templateUrl: './button-theme-dark.component.html',
  styleUrls: ['./button-theme-dark.component.scss']
})
export class ButtonThemeDarkComponent implements OnInit {
  @Select(AppHopState.selectColorScheme) colorScheme$: Observable<ColorSchemeName>;
  isDarkMode: boolean;
  lightOrNightModeIcon: 'light_mode' | 'mode_night' = 'mode_night';
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.colorScheme$.pipe(untilDestroyed(this)).subscribe((colorScheme) => {
      this.isDarkMode = colorScheme === ColorSchemeName.dark;
      this.lightOrNightModeIcon = this.isDarkMode ? 'light_mode' : 'mode_night';
    });
  }
  onSelectDarkOrLightTheme() {
    this.isDarkMode !== false
      ? this.store.dispatch(new ColorSchemeSet(ColorSchemeName.light))
      : this.store.dispatch(new ColorSchemeSet(ColorSchemeName.dark));
  }
}

import { BaseObjectModel } from './base.model';
import { OrderProduct } from './order-product.model';
import { UserModel } from './user';

export class ProviderModel extends BaseObjectModel {
  provider: ProviderName;
  priority: number;
  email: string;
  isValid: boolean;
  isVisible: boolean;
  body: any;
  constructor(data: any, autoFill = true) {
    super(data, autoFill);
  }
}

export enum ProviderName {
  google = 'google',
  microsoft = 'microsoft',
  local = 'local',
  zoom = 'zoom',
  bankPayment = 'bankPayment',
  cashPayment = 'cashPayment',
  stripeLive = 'stripeLive',
  stripeTest = 'stripeTest',
  paypal = 'paypal'
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserModel } from 'src/@hop/models';
import { UserState } from 'src/@hop/state';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FastSignInGlobalComponent } from '../fast-sign-in-global.component';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'hop-dialog-register-client',
  template: `<div class="">
    <div class="flex justify-between">
      <!--<h1 *ngIf="data?.type=='register'" mat-dialog-title class="text-sm sm:text-lg">{{ '_menu-user.register' |
      translate }}</h1>
    <h1 *ngIf="data?.type=='login'" mat-dialog-title class="text-sm sm:text-lg">{{ '_menu-user.login' | translate
      }}</h1>-->
      <h1 mat-dialog-title class="text-sm sm:text-lg">{{ 'sign-in' | translate }}</h1>
      <div mat-dialog-actions>
        <button mat-icon-button mat-dialog-close (click)="onNoClick()"><mat-icon svgIcon="mat:clear"></mat-icon></button>
      </div>
    </div>
    <div mat-dialog-content>
      <hop-fast-sign-in-global></hop-fast-sign-in-global>
    </div>
  </div> `,
  styleUrls: ['./dialog-register-client.component.scss'],
  imports: [CommonModule, FastSignInGlobalComponent, MatIconModule, MatButtonModule, MatDialogModule, TranslateModule]
})
export class DialogRegisterClientComponent implements OnInit {
  @Select(UserState.selectUser) user$: Observable<UserModel>;

  constructor(
    public dialogRef: MatDialogRef<DialogRegisterClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user) {
        this.dialogRef.close();
      }
    });
  }
}

import { mergeDeep } from '../utils/merge-deep';
import { HopConfigName } from './config-name.model';
import { HopConfig } from './hop-config.interface';
import { ColorSchemeName } from './colorSchemeName';
import { colorVariables } from '../components/config-panel/color-variables';
import * as cookie from 'js-cookie';
import colors from 'tailwindcss/colors';

// const isDark =

const defaultConfig: HopConfig = {
  id: HopConfigName.apollo,
  name: 'Apollo',
  style: {
    colorScheme: ColorSchemeName.light,
    colors: {
      primary: colorVariables.blue,
      accent: colorVariables.orange,
      warn: colorVariables.red
    },
    borderRadius: {
      value: 0.25,
      unit: 'rem'
    },
    button: {
      borderRadius: undefined
    }
  },
  direction: 'ltr',
  imgSrc: 'assets/img/logo.svg',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'hop',
    imageUrl: 'assets/img/logo.svg',
    showCollapsePin: true,
    user: {
      visible: true
    },
    search: {
      visible: true
    },
    state: 'expanded'
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true
    }
  },
  navbar: {
    position: 'below-toolbar'
  },
  footer: {
    visible: true,
    fixed: true
  }
};

export const configs: HopConfig[] = [
  defaultConfig,
  mergeDeep(
    { ...defaultConfig },
    {
      id: HopConfigName.poseidon,
      name: 'Poseidon',
      imgSrc: 'assets/img/logo.svg',
      style: {
        borderRadius: {
          value: 0.5,
          unit: 'rem'
        },
        button: {
          borderRadius: {
            value: 9999,
            unit: 'px'
          }
        }
      },
      sidenav: {
        user: {
          visible: true
        },
        search: {
          visible: true
        }
      },
      toolbar: {
        user: {
          visible: false
        }
      },
      footer: {
        fixed: false
      }
    }
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: HopConfigName.hermes,
      name: 'Hermes',
      imgSrc: 'assets/img/logo.svg',
      layout: 'vertical',
      boxed: true,
      sidenav: {
        user: {
          visible: false
        },
        search: {
          visible: false
        }
      },
      toolbar: {
        fixed: false
      },
      footer: {
        fixed: false
      }
    }
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: HopConfigName.ares,
      name: 'Ares',
      imgSrc: 'assets/img/logo.svg',
      sidenav: {
        user: {
          visible: false
        },
        search: {
          visible: false
        }
      },
      toolbar: {
        fixed: false
      },
      navbar: {
        position: 'in-toolbar'
      },
      footer: {
        fixed: false
      }
    }
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: HopConfigName.zeus,
      name: 'Zeus',
      imgSrc: 'assets/img/logo.svg',
      sidenav: {
        state: 'collapsed'
      }
    }
  ),
  mergeDeep(
    { ...defaultConfig },
    {
      id: HopConfigName.hop,
      name: 'hop',
      imgSrc: 'assets/img/logo.svg',
      layout: 'vertical',
      boxed: true,
      direction: 'ltr',
      colorScheme: ColorSchemeName.light,
      style: {
        borderRadius: {
          value: 0.5,
          unit: 'rem'
        },
        button: {
          borderRadius: {
            value: 9999,
            unit: 'px'
          }
        },
        colors: {
          primary: colorVariables.primary,
          accent: colorVariables.accent,
          warn: colorVariables.warn
        }
      },
      sidenav: {
        user: {
          visible: false
        },
        search: {
          visible: false
        }
      },
      toolbar: {
        fixed: true
      },
      navbar: {
        position: 'in-toolbar'
      },
      footer: {
        fixed: false
      }
    }
  )
];

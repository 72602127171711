import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Observable, Observer, Subject, Subscription } from 'rxjs';
import { FingerprintService } from './fingerprint.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter } from 'rxjs/operators';
import { SentryService } from './sentry.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class WebsocketService {
  wsOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Initialize channels as a Map
  channels: Map<string, Subject<any>> = new Map();
  appId = '';
  public subscriptionsComponentsMap = new Map<
    string,
    {
      channel: string;
      id: string;
      subscription: Subscription;
      componentName: string;
      fromWebsocket$: Subject<SubscriptionWebsocketResponse> | BehaviorSubject<SubscriptionWebsocketResponse>;
    }
  >();
  private removeAndCleanSubscriptionsFromSocketIO$: Subject<any> = new Subject();

  constructor(
    private socket: Socket,
    private fingerprintService: FingerprintService,
    private sentryService: SentryService
  ) {
    this.fingerprintService.getAppId().then((appId: string) => {
      this.appId = appId;
    });
    this.socket.fromEvent('disconnect').subscribe((data) => {
      this.wsOnline$.next(false);
      console.log('ws off');
    });

    this.socket.fromEvent('connect').subscribe((data) => {
      this.wsOnline$.next(true);
      console.log('ws on');
    });

    this.removeAndCleanSubscriptionsFromSocketIO$.pipe(debounceTime(1000)).subscribe(() => {
      //console.log('unsubscribe', 'removeAndCleanSubscriptionsFromSocketIO$', this.subscriptionsComponentsMap);
      this.removeAndCleanSubscriptionsFromSocketIO();
    });
  }

  addSubscription(channel: string, id: string): Observable<any> {
    let dateUpdated: number = 0;
    const dataToSend = { id: id, appId: this.appId, channel: channel, time: Date.now() };
    return new Observable((observer: Observer<any>) => {
      let channelSubscription: Subscription = this.getChannel(channel)
        .pipe(filter((data) => data && data.uuid === id))
        .subscribe((data) => {
          //console.log('ack:' + channel + ' ' + id, data);
          if (data && data.uuid && data.dateUpdated >= 0) {
            if (dateUpdated < +data.dateUpdated) {
              observer.next(data);
              //console.log('updated');
              dateUpdated = +data.dateUpdated;
            }
          } else {
            console.log(data);
            this.sentryService.captureException('No uuid or dateUpdated in data');
            // we'll send the data if we don't receive a standard response
            observer.next(data);
          }
        });
      const onlineSubscription = this.wsOnline$.pipe(filter((wsOnline) => wsOnline === true)).subscribe(() => {
        this.socket.emit(channel, dataToSend, (data: SubscriptionWebsocketResponse) => {
          //console.log('ack:' + channel + ' ' + id, data);
          if (data && data.uuid && data.dateUpdated >= 0) {
            if (dateUpdated < +data.dateUpdated) {
              //only send data if it is newer than the last one and if dateUpdated is set (this is because the first time we subscribe we don't have a dateUpdated)
              if (dateUpdated) {
                observer.next(data);
              }
              dateUpdated = data.dateUpdated;
            }
          } else {
            console.log(data);
            this.sentryService.captureException('No uuid or dateUpdated in data');
            // we'll send the data if we don't receive a standard response
            observer.next(data);
          }
        });
      });

      return () => {
        //console.log('unsubscribe', 'addSubscription');
        channelSubscription.unsubscribe();
        onlineSubscription.unsubscribe();
        this.removeAndCleanSubscriptionsFromSocketIO$.next(true);
      };
    });
  }

  /**
   * This method is used to add a subscription to a specific UUID.
   *
   * @param {string} uuid - The UUID to which the subscription is to be added.
   * @param {BehaviorSubject<SubscriptionWebsocketResponse>} fromWebsocket$ - The BehaviorSubject instance that will receive the data from the subscription.
   * @param {any} [component] - The component that will be used for the untilDestroyed operator. This is optional.
   * @param key
   *
   * @returns {Subscription} - Returns a Subscription instance. If fromWebsocket$ already has a value and force is not set to true, it returns null.
   *
   * @throws {Error} - Throws an error if the UUID is empty.
   */
  addUuidSubscription(uuid: string, fromWebsocket$: BehaviorSubject<SubscriptionWebsocketResponse>, component?: any): Subscription {
    if (!uuid) {
      this.sentryService.captureException('Uuid is empty in addUuidSubscription' + component?.constructor?.name);
      return null;
    }
    const componentName = component?.constructor?.name;
    const mapKey = this.generateMapKey('uuid', uuid, component);
    const existingSubscription = this.getSubscription('uuid', uuid, mapKey);

    if (existingSubscription && !existingSubscription.closed) {
      return existingSubscription;
    }

    let subscription: Observable<any>;

    // Unsubscribe when the component is destroyed only if it's a component
    if (component && typeof component['__ngContext__'] !== 'undefined') {
      //console.log('unsubscribe untilDestroyed active' + mapKey);
      // this is the only way to check if the component is destroyed, adding the pipe separately doesn't work
      subscription = this.addSubscription('uuid', uuid).pipe(untilDestroyed(component));
    } else {
      subscription = this.addSubscription('uuid', uuid);
    }

    const newSubscription = subscription.subscribe((data) => {
      fromWebsocket$.next(data);
    });

    // create a new entry if it doesn't exist in the subscriptionsComponentsMap with the new uuid, channel, component and subscription
    this.subscriptionsComponentsMap.set(mapKey, { channel: 'uuid', id: uuid, subscription: newSubscription, componentName, fromWebsocket$ });
    this.unsubscribeOldSubscriptions(mapKey);
    return newSubscription;
  }

  // this will be used to subscribe to a specific objects channel, like appointments, providers, integrations
  // the table parameter is the name of the object, like 'appointments', 'providers', 'integrations' and the userUuid is the uuid of the user
  addObjectsSubscription(
    table: string,
    userUuid: string,
    fromWebsocket$: BehaviorSubject<SubscriptionWebsocketResponse> | Subject<SubscriptionWebsocketResponse>,
    component: any,
    ignoreUntilDestroy = false
  ): Subscription {
    if (!table) {
      this.sentryService.captureException('table is empty in addUuidSubscription' + component?.constructor?.name);
    }
    if (!userUuid) {
      this.sentryService.captureException('Uuid is empty in addUuidSubscription' + component?.constructor?.name);
      return null;
    }

    const id = table + ':' + userUuid;

    const componentName = component?.constructor?.name;
    const mapKey = this.generateMapKey('table', id, component);
    const existingSubscription = this.getSubscription('table', id, mapKey);

    if (existingSubscription && !existingSubscription.closed) {
      return existingSubscription;
    }

    let subscription: Observable<any>;

    // Unsubscribe when the component is destroyed only if it's a component
    if (component && typeof component['__ngContext__'] !== 'undefined' && !ignoreUntilDestroy) {
      //console.log('unsubscribe untilDestroyed active' + mapKey);
      // this is the only way to check if the component is destroyed, adding the pipe separately doesn't work
      subscription = this.addSubscription('table', id).pipe(untilDestroyed(component));
    } else {
      subscription = this.addSubscription('table', id);
    }

    const newSubscription = subscription.subscribe((data) => {
      fromWebsocket$.next(data);
    });

    // create a new entry
    this.subscriptionsComponentsMap.set(mapKey, { channel: 'table', id, subscription: newSubscription, componentName, fromWebsocket$ });
    this.unsubscribeOldSubscriptions(mapKey);
    //console.log('unsubscribe', this.subscriptionsComponentsMap);
    return newSubscription;
  }

  removeSubscriptionFromSocketIO(channel: string, id: string): void {
    this.socket.emit(channel, { id: id, channel: channel, time: Date.now(), remove: true });
  }

  getChannel(channel: string): Subject<any> {
    if (!this.channels.has(channel)) {
      //console.log('new channel', channel);
      const newSubject = new Subject();
      this.channels.set(channel, newSubject);
      this.socket.on(channel, (data, ack) => {
        newSubject.next(data);
        if (ack) {
          ack({ message: 'received on channel:' + channel + ' ' + this.appId, success: true });
        }
      });
    }
    return this.channels.get(channel);
  }

  generateMapKey(channel: string, id: string, component: any): string {
    const viewId = component._get('__ngContext__.lViewId', '');
    return viewId + '-' + channel + '-' + component?.constructor?.name + id;
  }

  /**
   * Unsubscribes old subscriptions from the subscriptionsComponentsMap.
   *
   * This method is used to clean up old subscriptions that are no longer needed.
   * It first retrieves the current value from the subscriptionsComponentsMap using the provided map key.
   * Then it creates a list of keys to unsubscribe by filtering the keys of the subscriptionsComponentsMap.
   * It filters the keys based on the condition that the channel, id, and componentName of the value associated with the key match the current value's channel, id, and componentName.
   * Finally, it unsubscribes each of the subscriptions associated with the keys to unsubscribe.
   *
   * @param {string} currentMapKey - The key of the current value in the subscriptionsComponentsMap.
   */
  public unsubscribeOldSubscriptions(currentMapKey: string): void {
    // Get the current value from the subscriptionsComponentsMap
    const currentValue = this.subscriptionsComponentsMap.get(currentMapKey);
    const { id, channel, componentName } = currentValue;

    // Get all the keys from the subscriptionsComponentsMap
    const keys = Array.from(this.subscriptionsComponentsMap.keys());

    // Filter the keys to get the keys to unsubscribe by returning all the channel and componentName matches and id mismatches
    const valuesToUnsubscribe = keys.filter((k) => {
      const value = this.subscriptionsComponentsMap.get(k);
      return (
        value.channel === channel && value.id !== id && value.componentName === componentName && value.fromWebsocket$ === currentValue.fromWebsocket$
      );
    });

    // Unsubscribe each of the subscriptions associated with the keys to unsubscribe
    for (const key of valuesToUnsubscribe) {
      const value = this.subscriptionsComponentsMap.get(key);
      if (value.subscription && !value.subscription.closed) {
        value.subscription.unsubscribe();
      }
    }
  }

  // this method will return the current subscription for a specific channel, component and id
  // it will check if the component has a subscription with the same UUID
  // if it has, it will return the subscription
  // if it doesn't have, it will return null
  private getSubscription(channel: string, id: string, mapKey: string): Subscription {
    const value = this.subscriptionsComponentsMap.get(mapKey);
    if (value) {
      return value.subscription;
    }
    return null;
  }

  // this function will go through all the values in the subscriptionsComponentsMap and if there are subscriptions closed that don't have other subscriptions open based on channel and id then
  // call removeSubscriptionFromSocketIO
  removeAndCleanSubscriptionsFromSocketIO() {
    // Create an array of closed subscriptions
    const closedSubscriptions = Array.from(this.subscriptionsComponentsMap.entries()).filter(([key, value]) => value.subscription.closed);

    // Iterate over the array of closed subscriptions
    for (const [key, value] of closedSubscriptions) {
      // Remove the subscription from the subscriptionsComponentsMap
      this.subscriptionsComponentsMap.delete(key);

      // Check if there are no other subscriptions with the same channel and id
      const hasOtherSubscriptions = Array.from(this.subscriptionsComponentsMap.values()).some(
        (subscription) => subscription.channel === value.channel && subscription.id === value.id
      );

      // If there are no other subscriptions with the same channel and id, call removeSubscriptionFromSocketIO
      if (!hasOtherSubscriptions) {
        this.removeSubscriptionFromSocketIO(value.channel, value.id);
      }
    }
    //console.log('unsubscribe', 'after removeAndCleanSubscriptionsFromSocketIO$', this.subscriptionsComponentsMap);
  }
}

export interface SubscriptionWebsocket {
  channel: string;
  id: string;
}

export interface SubscriptionWebsocketResponse {
  uuid: string;
  dateUpdated: number;
}

import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  standalone: true,
  selector: '[hopRouterLink]'
})
export class AppRouterLinkDirective implements OnInit {
  @Input('hopRouterLink') _uri: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router) {}

  async ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, 'cursor-pointer');
    if (this.el.nativeElement.tagName.toLowerCase() !== 'img') {
      this.renderer.addClass(this.el.nativeElement, 'underline');
    }
    this.el.nativeElement.addEventListener('click', () => {
      this.router.navigateByUrl(this._uri);
    });
  }
}

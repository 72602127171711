import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { LOADING_BAR_CONFIG, LoadingBarConfig } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [ProgressBarComponent],
  imports: [CommonModule, LoadingBarRouterModule, HttpClientModule, LoadingBarHttpClientModule],
  exports: [ProgressBarComponent],
  providers: [
    {
      provide: LOADING_BAR_CONFIG,
      useValue: {
        latencyThreshold: 300
      } as LoadingBarConfig
    }
  ]
})
export class ProgressBarModule {}

import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import * as cookie from 'js-cookie';
import { environment } from '../../environments/environment';

@Injectable()
export class LangPreserveQueryLocationStrategy extends PathLocationStrategy {
  private lang = 'en';

  /*constructor(_platformLocation: PlatformLocation, @Optional() @Inject(APP_BASE_HREF) _baseHref?: string) {
    super(_platformLocation, _baseHref);
  }*/

  prepareExternalUrl(internal: string): string {
    const defaultLangCode = environment.defaultLangCode || 'en';
    this.lang = cookie.get('lang') || defaultLangCode;
    let url = super.prepareExternalUrl(internal);
    // comment this if you want to keep the lang query parameter at all times
    if (this.lang !== defaultLangCode) {
      url = url.replace(/[&]?lang=../, '');
      if (/\?/.test(url)) {
        url = url + '&lang=' + this.lang;
      } else {
        url = url + '?lang=' + this.lang;
      }
    }
    return url.replace(/\?&/, '?');
  }
}
